import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import './DatePicker.css';

const StyledDatePicker = ( props: any ) => {
    return(<>
        <DatePicker
            className={`p-0.5 pl-1.5 pr-1.5 w-full bg-slate-500 border-2 border-transparent focus:border-slate-400/50 text-slate-100 h-8 outline-none ${props.className ? props.className : ''}`}
            calendarClassName='bg-slate-500 p-4 border-slate-400 !border-2 drop-shadow-xl'
            calendarAriaLabel="Toggle calendar"
            clearAriaLabel="Clear value"
            dayAriaLabel="Day"
            clearIcon={null}
            calendarIcon={null}
            monthAriaLabel="Month"
            format='dd.MM.y'
            locale='no'
            nativeInputAriaLabel="Date"
            onChange={(date: any) => {props.setSelected(date)}}
            value={props.selected}
            yearAriaLabel="Year"
        />
    </>);
}

export default StyledDatePicker;