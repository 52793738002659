export const getDomener = (page: number = 1, search: string, sortBy: string = '', isAsc: boolean, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/domener?p=${page}&s=${search}&sb=${sortBy}&a=${isAsc ? 1 : 0}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const deleteDomene = (dbId: number, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/domener/${dbId}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const putDomene = (dbId: number = 0, data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/domener/${dbId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postDomene = (data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/domener`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}