import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import session from '../features/session'
import dashboard from '../features/dashboard'

const persistConfig = {
  key: 'root',
  storage,
};

const persistedSession = persistReducer(persistConfig, session);

export const store = configureStore({
  reducer: {
    session: persistedSession,
    dashboard: dashboard
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);