import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { set as setSession } from './../../features/session'
import logo_svg from './../../assets/svg/logo.svg';
import { login } from './../../api/auth';
import Button from '../Button';
import Notification from '../Notification';
import Input from '../Input';
import Message from '../../types/Message';

const LoginForm = () => {
  const [brukernavn, setBrukernavn] = useState('');
  const [passord, setPassord] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(new Message('', 0));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleLogin() {
    setLoading(true);

    login(brukernavn, passord, (json : any) => {
      if (loading) {
        return;
      }

      navigate('/');
      setMessage(new Message(json.message, json.messageType));

      setTimeout(() => {
        setLoading(false);
        dispatch(setSession(json));
      }, json.messageType == 1 ? 1500 : 0)
    });
  }

  return (
    <div className='text-white w-96'>
        <img src={logo_svg} className='mb-6 h-12 ml-auto mr-auto' alt='logo' />

        <div className='bg-slate-700 p-8 pt-12 pb-12'>
          <Notification message={message} />
          <h3 className='mb-1'>Brukernavn</h3>
          <Input
            value={brukernavn}
            onChange={(e: any) => setBrukernavn(e.target.value)}
            name='username'
          />
          <h3 className='mt-4 mb-1'>Passord</h3>
          <Input
            value={passord}
            onChange={(e: any) => setPassord(e.target.value)}
            type='password'
          />
      
          <Button onClick={handleLogin} isLoading={loading} className='w-full justify-center mt-6'>
            Login
          </Button>
        </div>
    </div>
  );
}

export default LoginForm;
