import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { BiCog } from 'react-icons/bi';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';

import PageContainer from '../components/PageContainer';
import LogoContainer from '../components/LogoContainer';
import { Accordion, AccordionOption } from '../components/Accordion';
import FakturaGrunnlag from '../components/Salg/FakturaGrunnlag'
import TilbudTable from '../components/Salg/TilbudTable';

import { numberToMonth, numToLocale } from '../utils/util'
import { getFaktureringer as fetchFaktureringer, getOppsummering as fetchOppsummering, deleteUtgift } from '../api/okonomi';

var tomOppsummering: any = [];

for (let i = 1; i <= 12; i++) {
  tomOppsummering[i - 1] = {name: numberToMonth[i], Inntekter: 0, Utgifter: 0};
}

var tomFaktureringsData: any = [];

for (let i = 1; i <= 12; i++) {
  tomFaktureringsData[i - 1] = {
    kunder: [],
    total: 0
  };
}

function Salg() {
  const [faktureringsData, setFaktureringsData] = useState(tomFaktureringsData);

  useEffect(() => {
    fetchFaktureringer((json: any) => {
      setFaktureringsData(json);
    });
  }, []);

  return (<>
    <Helmet>
      <title>Webtron - Salg</title>
    </Helmet>

    <PageContainer>
      <TilbudTable
        title='Tilbud'
        icon={MdOutlineLocalOffer}
        containerClassName='col-span-6'
      ></TilbudTable>

      <LogoContainer className='mt-8 min-h-96' titleClassName='mb-6' icon={LiaFileInvoiceDollarSolid} title='Fakturering'>
        <Accordion startClosed={true}>
          {faktureringsData.map((maaned: any, i: number) => {
            return(
              <AccordionOption
                title={numberToMonth[i + 1]}
                key={`maaned_${i}`}
                rightTitle={`${numToLocale(maaned.total)}`}
                rightTitleClass='font-bold'
              >
                <Accordion>
                {maaned.kunder.map((kunde: any, i: number) => {
                  if (kunde.total <= 0)
                    return;
                  
                  return (
                    <AccordionOption
                      borderButton={true}
                      droppedBgCol='bg-slate-500'
                      title={kunde.navn}
                      key={`fgkun_${kunde.kunde_id}`}
                      rightTitle={`${numToLocale(kunde.total)}`}
                      titleButton={<Link to={`/kunder/${kunde.kunde_id}`}><BiCog className='transition ease-in-out hover:text-blue-300 hover:rotate-90' size={22}/></Link>}
                    >
                      <FakturaGrunnlag kundeId={kunde.kunde_id} tjenester={kunde.tjenester} total={kunde.total}></FakturaGrunnlag>
                    </AccordionOption>
                  )
                })}
                </Accordion>
              </AccordionOption>
            );
          })}
        </Accordion>
      </LogoContainer>
    </PageContainer>
  </>);
}

export default Salg;
