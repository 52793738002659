import { NavLink as Link } from "react-router-dom";

const NavLink = ( props : any ) => {
    return (
      <Link
        className={(state) => `flex items-center text-md h-full text-slate-200 border-r-2 pr-5 border-slate-700/50 last:border-r-0 hover:opacity-100 ${state.isActive ? 'group is-active' : 'hover:text-slate-200/50'}`}
        to={props.to}>
        <props.icon size={20} className='transition mr-2 group-[.is-active]:text-webtron group-[.is-active]:scale-125'/>{props.children}</Link>
    );
};
    
export default NavLink;