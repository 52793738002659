import { BiCheck, BiX } from 'react-icons/bi';

const Checkbox = (props: any) => {
    return (<>
        <button
            className='relative flex justify-center items-center bg-slate-500 border-2 border-transparent focus:border-slate-400/50 text-slate-100 h-8 w-8 outline-none'
            onClick={props.onClick}
        >
            {props.isChecked ? <BiCheck className='text-green-300' size={26}/> : <BiX className='text-red-300' size={26}/>}
        </button>
    </>)
}

export default Checkbox;