import { useEffect, useState } from 'react';
import Modal from '../Modal';
import Input from '../Input';
import TextArea from '../TextArea';
import Button from '../Button';
import DatePicker from '../DatePicker';
import { BiPencil, BiPlus } from 'react-icons/bi';

import { createDate } from '../../utils/util';
import { putNotat, postNotat } from '../../api/notater'

const HendelseModal = (props: any) => {
    const [notatDato, setHendelseDato] = useState(new Date());
    const [notatTekst, setHendelseTekst] = useState('');

    const sendUpdate = (props: any) => {
        const parsed: any = {
            dato: notatDato ? Math.floor(notatDato.getTime() / 1000) : null,
            tekst: notatTekst || '',
            kunde_id: props.notatData.kunde_id
        }

        var modified = false;

        for (let key in parsed) {
            if (props.notatData[key] != parsed[key])
            {
                modified = true;
                break;
            }
        };

        if (!modified)
            return props.setOpen(false);

        const onFinished = () => {
            props.setOpen(false);
            props.updateTable();
        }

        if (props.notatData.ny) {
            postNotat(parsed, onFinished);
        } else {
            putNotat(props.notatData.id, parsed, onFinished);
        }
    }

    useEffect(() => {
        setHendelseDato(createDate(props.notatData.dato, true));
        setHendelseTekst(props.notatData.tekst);
    }, [props.notatData])
  
    return(<>
      <Modal
        title={props.notatData.ny ? 'Nytt notat' : 'Rediger notat'} 
        icon={props.notatData.ny ? BiPlus : BiPencil} 
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <div className='flex flex-col'>
                <h3 className='mb-1'>Dato</h3>
                <DatePicker isClearable showIcon selected={notatDato} setSelected={setHendelseDato} />
            </div>

            <h3 className='mt-4 mb-1'>Tekst</h3>
            <TextArea value={notatTekst} onChange={(e: any) => setHendelseTekst(e.target.value)} type='text'/>
            
            <Button
                className='mt-6'
                type="submit"
            >
                {props.notatData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default HendelseModal;