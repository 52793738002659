import { useEffect, useState } from 'react';
import Modal from '../../Modal';
import Input from '../../Input';
import TextArea from '../../TextArea';
import Button from '../../Button';
import DatePicker from '../../DatePicker';
import { BiPencil, BiPlus } from 'react-icons/bi';

import { createDate } from '../../../utils/util';
import { putTimebank, postTimebank } from '../../../api/timebanker'

const TimebankModal = (props: any) => {
    const [timebankDato, setHendelseDato] = useState(new Date());
    const [timebankBeskrivelse, setTimebankBeskrivelse] = useState('');
    const [timebankTimer, setTimebankTimer] = useState(0);

    const sendUpdate = (props: any) => {
        const parsed: any = {
            dato: timebankDato ? Math.floor(timebankDato.getTime() / 1000) : null,
            beskrivelse: timebankBeskrivelse || '',
            timer: timebankTimer || 0,
            kunde_id: props.timebankData.kunde_id
        }

        var modified = false;

        for (let key in parsed) {
            if (props.timebankData[key] != parsed[key])
            {
                modified = true;
                break;
            }
        };

        if (!modified)
            return props.setOpen(false);

        const onFinished = () => {
            props.setOpen(false);
            props.updateTable();
        }

        if (props.timebankData.ny) {
            postTimebank(parsed, onFinished);
        } else {
            putTimebank(props.timebankData.id, parsed, onFinished);
        }
    }

    useEffect(() => {
        setHendelseDato(createDate(props.timebankData.dato, true));
        setTimebankBeskrivelse(props.timebankData.beskrivelse);
        setTimebankTimer(props.timebankData.timer);
    }, [props.timebankData])
  
    return(<>
      <Modal
        title={props.timebankData.ny ? 'Ny timebank' : 'Rediger timebank'} 
        icon={props.timebankData.ny ? BiPlus : BiPencil} 
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <div className='grid grid-cols-2 gap-6'>
                <div className='flex flex-col'>
                    <h3 className='mb-1'>Dato</h3>
                    <DatePicker isClearable showIcon selected={timebankDato} setSelected={setHendelseDato} />
                </div>

                <div className='flex flex-col'>
                    <h3 className='mb-1'>Timer</h3>
                    <Input type='number' value={timebankTimer} onChange={(e: any) => setTimebankTimer(e.target.value)} parentClass='w-full'/>
                </div>

                <div className='flex flex-col col-span-2'>
                    <h3 className='mb-1'>Beskrivelse</h3>
                    <TextArea value={timebankBeskrivelse} onChange={(e: any) => setTimebankBeskrivelse(e.target.value)} type='text'/>
                </div>

            </div>
            
            <Button
                className='mt-6'
                type="submit"
            >
                {props.timebankData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default TimebankModal;