import { useEffect, useState } from 'react';
import Modal from '../Modal';
import Input from '../Input';
import TextArea from '../TextArea';
import Button from '../Button';
import DatePicker from '../DatePicker';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import { BiPencil, BiPlus } from 'react-icons/bi';

import { createDate, numberToMonth, monthToNumber, undefinedAndEmptyOrZero } from '../../utils/util';
import { putKunde, postKunde } from '../../api/kunder'

const KundeModal = (props: any) => {
    const [kundeSiden, setKundeSiden] = useState(new Date());
    const [kundeKontaktPerson, setKundeKontaktPerson] = useState('');
    const [kundeTelefon, setKundeTelefon] = useState('');
    const [kundeEpost, setKundeEpost] = useState('');
    const [kundeFakturaMaaned, setKundeFakturaMaaned] = useState(0);
    const [kundeNavn, setKundeNavn] = useState('');
    const [kundeMerknader, setKundeMerknader] = useState('');
    const [kundeSisteRedesign, setKundeSisteRedesign] = useState(new Date());
    const [kundeVisKontaktperson, setKundeVisKontaktperson] = useState(true);

    const sendUpdate = (props: any) => {
        const parsed: any = {
            firma_navn: kundeNavn || '',
            kontakt_person: kundeKontaktPerson || '',
            epost: kundeEpost || '',
            telefon: kundeTelefon || 0,
            kunde_siden: kundeSiden ? Math.floor(kundeSiden.getTime() / 1000) : null,
            faktura_maaned: monthToNumber[kundeFakturaMaaned] || 1,
            merknader: kundeMerknader || '',
            siste_redesign: kundeSisteRedesign ? Math.floor(kundeSisteRedesign.getTime() / 1000) : null,
            vis_kontaktperson: kundeVisKontaktperson ? 1 : 0,
            kundeId: props.kundeData.id,
        }

        var modified = false;

        for (let key in parsed) {
            if (props.kundeData[key] != parsed[key])
            {
                modified = true;
                break;
            }
        };

        if (!modified)
            return;

        const onFinished = () => {
            props.setOpen(false);
            props.performUpdate();
        }

        if (props.kundeData.ny) {
            postKunde(parsed, onFinished);
        } else {
            putKunde(props.kundeData.id, parsed, onFinished);
        }
    }

    useEffect(() => {
        const curMaaned = new Date(Date.now());

        setKundeNavn(props.kundeData.firma_navn || '');
        setKundeKontaktPerson(props.kundeData.kontakt_person || '');
        setKundeEpost(props.kundeData.epost || '');
        setKundeTelefon(props.kundeData.telefon || '');
        setKundeSiden(createDate(props.kundeData.kunde_siden, true));
        setKundeMerknader(props.kundeData.merknader || '');
        setKundeFakturaMaaned(numberToMonth[props.kundeData.faktura_maaned] || numberToMonth[curMaaned.getMonth() + 1]);
        setKundeSisteRedesign(createDate(props.kundeData.siste_redesign));
        setKundeVisKontaktperson(props.kundeData.vis_kontaktperson);
    }, [props.kundeData])
  
    return(<>
      <Modal
        title={props.kundeData.ny ? 'Ny kunde' : 'Rediger kunde'} 
        icon={props.kundeData.ny ? BiPlus : BiPencil}
        sizeClass='max-w-2xl'
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <div className='grid grid-cols-2 gap-6'>
                <div className='flex flex-col'>
                    <h3 className='mb-1'>Navn</h3>
                    <Input value={kundeNavn} onChange={(e: any) => setKundeNavn(e.target.value)} type='text'/>
                </div>

                <div className='flex flex-col'>
                    <h3 className='mb-1'>Kontaktperson</h3>
                    <Input value={kundeKontaktPerson} onChange={(e: any) => setKundeKontaktPerson(e.target.value)} type='text'/>
                </div>

                <div className='flex flex-col'>
                    <h3 className='mb-1'>Telefon</h3>
                    <Input value={kundeTelefon} onChange={(e: any) => setKundeTelefon(e.target.value)} type='number'/>
                </div>

                <div className='flex flex-col'>
                    <h3 className='mb-1'>Epost</h3>
                    <Input value={kundeEpost} onChange={(e: any) => setKundeEpost(e.target.value)} type='text'/>
                </div>

                <div className='flex flex-col'>
                    <h3 className='mb-1'>Fakturamåned</h3>
                    <Dropdown value={kundeFakturaMaaned} options={['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']} setValue={(val: any) => setKundeFakturaMaaned(val)}/>
                </div>

                <div className='flex flex-col'>
                    <h3 className='mb-1'>Kunde siden</h3>
                    <DatePicker showIcon selected={kundeSiden} setSelected={setKundeSiden} />
                </div>

                <div className='flex flex-col'>
                    <h3 className='mb-1'>Siste redesign</h3>
                    <DatePicker isClearable showIcon selected={kundeSisteRedesign} setSelected={setKundeSisteRedesign} />
                </div>

                
                <div className='flex flex-col'>
                    <h3 className='mb-1'>Vis kontaktperson</h3>
                    <Checkbox isChecked={kundeVisKontaktperson} onClick={(e: any) => {e.preventDefault();setKundeVisKontaktperson(!kundeVisKontaktperson)}}/>
                </div>

                <div className='flex flex-col col-span-2'>
                    <h3 className='mb-1'>Merknader</h3>
                    <TextArea value={kundeMerknader} onChange={(e: any) => setKundeMerknader(e.target.value)} type='text'/>
                </div>
            </div>

            <Button
                className='mt-6'
                type="submit"
            >
                {props.kundeData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default KundeModal;