export const getUtgifter = (type: number = 0, page: number = 1, search: string, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/okonomi/utgifter?t=${type}&p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const deleteUtgift = (id: number = 0, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/okonomi/utgifter/${id}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const putUtgift = (id: number = 0, data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/okonomi/utgifter/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postUtgift = (data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/okonomi/utgifter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const getFaktureringer = (callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/okonomi/fakturering`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const getOppsummering = (callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/okonomi/oppsummering`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const getTimebanker = (page: number = 1, search: string, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/okonomi/timebanker?p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}