export const formatDateShort = (dateString: string) => {
    const options: any = { month: "short", day: "numeric"}
    return new Date(dateString).toLocaleDateString("no-NO", options)
}

export const formatDate = (dateString: string) => {
    const options: any = { year: "numeric", month: "short", day: "numeric"}
    var date = new Date(dateString);

    if (date.getTime() <= 0 || isNaN(date.getTime()))
        return 'N/A'

    return date.toLocaleDateString("no-NO", options)
}

export const numToLocale = (num: number) => {
    num = num || 0
  
    return `kr ${num.toLocaleString('no')}.-`
}

export const numberToMonth : any = {
    1: 'Januar',
    2: 'Februar',
    3: 'Mars',
    4: 'April',
    5: 'Mai',
    6: 'Juni',
    7: 'Juli',
    8: 'August',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Desember'
}

export const monthToNumber : any = {
    'Januar': 1,
    'Februar': 2,
    'Mars': 3,
    'April': 4,
    'Mai': 5,
    'Juni': 6,
    'Juli': 7,
    'August': 8,
    'September': 9,
    'Oktober': 10,
    'November': 11,
    'Desember': 12
}

export const createDate = (date: string, fallbackNow: boolean = false) => {
    if (date == null) {
        if (fallbackNow) {
            return new Date(Date.now());
        }

        return;
    }
    
    var dateConverted: any = new Date(date);

    if (isNaN(dateConverted.getTime())) {
        dateConverted = null;
    }

    return dateConverted;
}

export const numberToCurrency : any = {
    0: 'NOK',
    1: 'USD',
    2: 'EUR'
}

export const currencyToNumber : any = {
    'NOK': 0,
    'USD': 1,
    'EUR': 2
}

export const undefinedAndEmptyOrZero = (val: any) => {
    return (typeof val === 'undefined') ? '' : (val || 0)
}