import { numToLocale } from '../../utils/util'
const FakturaGrunnlag = ( props : any ) => {
  return (<>    
    <div className='p-1'>
        <div className='flex flex-col'>
            {props.tjenester.map((tjeneste: any, i: number) => {
                return <div key={`fgl_kun_${props.kundeId}_${i}`} className='flex justify-between w-full border-b-1 pt-1 pb-1 border-slate-400 last:border-b-2 last:pb-2'><p>{tjeneste.tjeneste}</p><p>{numToLocale(tjeneste.pris)}</p></div>
            })}
        </div>

        <div className='flex justify-between w-full pt-1 pb-1 mt-1 font-bold'>
            <p>Sum</p><p>{numToLocale(props.total)}</p>
        </div>

    </div>
  </>);
}

export default FakturaGrunnlag;
