import { useEffect, useState } from 'react';
import Modal from '../Modal';
import Input from '../Input';
import Button from '../Button';

import { BiPencil, BiPlus, BiRotateRight } from 'react-icons/bi';
import { putDomene, postDomene } from '../../api/domener'
import { undefinedAndEmptyOrZero } from '../../utils/util';
import cryptoRandomString from 'crypto-random-string';

const DomeneModal = (props: any) => {
    const [domeneNavn, setDomeneNavn] = useState('');
    const [domenePris, setDomenePris] = useState(0);
    const [domeneMerknader, setDomeneMerknader] = useState('');
    const [domeneAPIKey, setDomeneAPIKey] = useState('');
    const [domeneTags, setDomeneTags] = useState('');

    const sendUpdate = (props: any) => {
        const parsed: any = {
            navn: domeneNavn,
            pris: domenePris || 0,
            merknader: domeneMerknader || '',
            kunde_id: props.domeneData.kunde_id,
            api_key: domeneAPIKey || '',
            tags:  domeneTags || ''
        }

        var modified = false;

        for (let key in parsed) {
            if (props.domeneData[key] != parsed[key]) {
                modified = true;
                break;
            }
        };

        if (!modified)
            return props.setOpen(false);

        const onFinished = () => {
            props.setOpen(false);
            props.updateTable();
        }

        if (props.domeneData.ny) {
            postDomene(parsed, onFinished);
        } else {
            putDomene(props.domeneData.id, parsed, onFinished);
        }
    }

    useEffect(() => {
        setDomeneNavn(props.domeneData.navn || '');
        setDomenePris(undefinedAndEmptyOrZero(props.domeneData.pris));
        setDomeneMerknader(props.domeneData.merknader || '');
        setDomeneAPIKey(props.domeneData.api_key || '');
        setDomeneTags(props.domeneData.tags || '');
    }, [props.domeneData])

    const generateAPIKey = () => {
        setDomeneAPIKey(cryptoRandomString({length: 32, type: 'alphanumeric'}));
    }
  
    return(<>
      <Modal
        title={props.domeneData.ny ? 'Nytt domene' : 'Rediger domene'} 
        icon={props.domeneData.ny ? BiPlus : BiPencil} 
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <div className='grid grid-cols-1 md:grid-cols-8 gap-6'>
                <div className='flex flex-col col-span-6'>
                    <h3 className='mb-1'>Navn</h3>
                    <Input value={domeneNavn} onChange={(e: any) => setDomeneNavn(e.target.value)} type='text'/>
                </div>

                <div className='flex flex-col col-span-2'>
                    <h3 className='mb-1'>Pris</h3>
                    <Input type='number' value={domenePris} onChange={(e: any) => setDomenePris(e.target.value)} parentClass='w-full'/>
                </div>
            </div>

            <h3 className='mt-4 mb-1'>Merknader</h3>
            <Input value={domeneMerknader} onChange={(e: any) => setDomeneMerknader(e.target.value)} type='text'/>

            <h3 className='mt-4 mb-1'>API Nøkkel</h3>
            <div className='relative'>
                <Input value={domeneAPIKey} onChange={(e: any) => setDomeneAPIKey(e.target.value)} type='text'/>
                <a onClick={generateAPIKey} className='cursor-pointer absolute right-1 top-1/2 -translate-y-1/2 transition hover:rotate-45 hover:opacity-50'><BiRotateRight size={22}/></a>
            </div>

            <h3 className='mt-4 mb-1'>Tags</h3>
            <Input value={domeneTags} onChange={(e: any) => setDomeneTags(e.target.value)} type='text'/>

            <Button
                className='mt-6'
                type="submit"
            >
                {props.domeneData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default DomeneModal;