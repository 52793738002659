import { useState, cloneElement, isValidElement } from 'react';
import { IoIosArrowDown } from 'react-icons/io'

export const Accordion = ( props : any ) => {
    const [droppedDown, setDroppedDown] = useState(props.startClosed ? -1 : 0);

    const childrenWithProps = props.children.map((child: any, index: any) => {
        if (isValidElement(child)) {
          return cloneElement(child as React.ReactElement, { handleDropdown: () => {setDroppedDown(droppedDown == index ? -1 : index)}, droppedDown: index == droppedDown });
        }

        return child;
    });

    return (
        <div
            className={`gap-2 flex flex-col`}
        >
            {childrenWithProps}
        </div>
    );
}

export const AccordionOption = ( props : any ) => {
    return (     
        <div 
            className={`
                transition duration-500 ease-in-out w-full p-3 pt-0 text-white overflow-y-hidden
                ${props.droppedDown ? `${props.droppedBgCol ? props.droppedBgCol : 'bg-slate-600/50'} h-fit` : `${props.bgCol ? props.bgCol : 'bg-slate-500/50'} h-12`}
            `}
        >
            <button 
                onClick={props.handleDropdown}
                className='h-12 w-full flex items-center justify-between group hover:opacity-80'
                key={props.key}
            >
                <div className='w-full text-left flex justify-between pr-4'>
                    <span className='flex items-center gap-2'>{props.title}{props.titleButton && props.titleButton}</span>
                    <span className={props.rightTitleClass}>{props.rightTitle}</span>
                </div>
                <IoIosArrowDown className={`transition duration-500 ease-in-out ${props.droppedDown ? 'rotate-0' : 'rotate-180 group-hover:opacity-60'}`} size={24}/>
            </button>
            {props.borderButton && <div className='border-b-2 border-slate-400'></div>}
            {props.children}
        </div>
    );
}