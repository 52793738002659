
const getDashboard = (callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + '/dashboard')
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export default getDashboard;