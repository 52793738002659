import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { store } from './app/store';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { set } from './features/session'
import fetchSession from './api/fetchSession';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Kunder from './pages/Kunder';
import VisKunde from './pages/VisKunde';
import Økonomi from './pages/Økonomi';
import Salg from './pages/Salg';
import Applikasjoner from './pages/Applikasjoner';
import Domener from './pages/Domener';
import Prosjekter from './pages/Prosjekter';

import Navbar from './components/Navbar';
import './index.css';

export default function App() {
  const session = useSelector((state : any) => state.session.value);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSession((json : any) => {
      dispatch(set(json));
    });
  }, [])

  return (<>
    {session.loggedIn ?
      <div>
        <Navbar/>
        <div className='content w-full'>
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path='/kunder' element={<Kunder />} />
            <Route path='/kunder/:kundeId' element={<VisKunde />} />
            <Route path='/økonomi' element={<Økonomi />} />
            <Route path='/salg' element={<Salg />} />
            <Route path='/applikasjoner' element={<Applikasjoner />} />
            <Route path='/domener' element={<Domener />} />
            <Route path='/prosjekter' element={<Prosjekter />} />
          </Routes>
        </div>
      </div>
    :
      <Login />
    }
  </>);
}

const root_element = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(root_element);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />  
    </BrowserRouter>
  </Provider>
);