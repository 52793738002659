import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import ContextMenu, { ContextButton } from '../..//ContextMenu';
import InntektModal from './InntektModal';
import Table, { TableEntry } from '../..//Table';
import Button from '../..//Button';

import { numToLocale, numberToMonth } from '../../..//utils/util';
import { getInntekter, deleteInntekt } from '../../../api/kunder';

import { CgMathPlus } from 'react-icons/cg';
import { HiOutlineCash } from "react-icons/hi";

const InntektTable = (props: any) => {
    const [selectedInntekt, setSelectedInntekt] = useState(-1);
    const [modalInntektData, setModalInntektData] = useState({});
    const [tjenesteData, setTjenesteData] = useState<any>({results: []});

    const [curPage, setCurPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [debouncedSearch] = useDebounce(searchVal, 300);
    const { kundeId } = useParams() as any;

    const updateTable = () => {
      setIsLoading(true);

      getInntekter(kundeId, curPage, debouncedSearch, (json: any) => {
        setTjenesteData(json);
        setIsLoading(false);
      });

      props.getKunde();
    }

    useEffect(() => {
      updateTable();
    }, [debouncedSearch, curPage]);
  
    return (<>
      <div className={props.containerClassName}>
        <ContextMenu isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
          <ContextButton
            onClick={() => {setModalInntektData(tjenesteData.results[selectedInntekt]);setIsEditModalOpen(true)}}
          >Rediger</ContextButton>
          <ContextButton
            onClick={() => {deleteInntekt(kundeId, tjenesteData.results[selectedInntekt].id, updateTable)}}
            >Slett</ContextButton>
        </ContextMenu>
    
        {isEditModalOpen && <InntektModal setOpen={setIsEditModalOpen} updateTable={updateTable} inntektData={modalInntektData}/>}
    
        <Table
          title={'Inntekter'}
          icon={HiOutlineCash}
          iconClassName={props.iconClassName}
          className={`min-h-96 ${props.className ? props.className : ''}` }
          showPaginator={tjenesteData['pageCount'] > 1}
          curPage={curPage}
          searchValue={searchVal}
          isEmpty={!tjenesteData.results || tjenesteData.results.length <= 0}
          onSearchChange={(e: any) => {
            setSearchVal(e.target.value);
          }}
          tabButtons={
              <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setModalInntektData({'ny': true, 'kunde_id': kundeId}); setIsEditModalOpen(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
          }
          pageCount={tjenesteData['pageCount']}
          containerClassName={'grow'}
          isLoading={isLoading}
          callbackPage={setCurPage}
          header={['Tjeneste', 'Pris', 'Fakturamåned']}>
            {tjenesteData.results.map((tjeneste: any, index: number) => {
              return <TableEntry
                callbackContextMenu={() => {setSelectedInntekt(index); setIsDropdownOpen(true)}}
                key={`kun_tnst_${tjeneste.id}`}
                passedKey={`kun_tnst_${tjeneste.id}`}
                data={[
                  tjeneste.tjeneste,
                  numToLocale(tjeneste.pris),
                  numberToMonth[tjeneste.faktura_maaned || props.kundeFakturaMaaned] || 'N/A',
                ]}/>
            })}
        </Table>
      </div>
    </>);
}

export default InntektTable;