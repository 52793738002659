export const deleteTimebank = (dbId: number, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/timebanker/${dbId}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const putTimebank = (dbId: number = 0, data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/timebanker/${dbId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postTimebank = (data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/timebanker`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const putTimebankLinje = (dbId: number = 0, data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/timebanker/line/${dbId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const deleteTimebankLinje = (dbId: number, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/timebanker/line/${dbId}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postTimebankLinje = (dbId: number = 0, data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/timebanker/${dbId}/line`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const getTimebankLinjer = (timebank: number, page: number = 1, search: string, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/timebanker/${timebank}?p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}