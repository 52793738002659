import { Helmet } from 'react-helmet';
import LoginForm from '../components/Login/LoginForm';

function Login() {
  return (<>
    <Helmet>
      <title>Webtron - Login</title>
    </Helmet>

    <div className='w-screen h-screen flex justify-center items-center'>
      <LoginForm/>
    </div>
  </>);
}

export default Login;
