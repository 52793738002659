import { useState, useEffect } from 'react';
import { IconType } from 'react-icons/lib';
import { AiFillWarning, AiFillCheckCircle } from 'react-icons/ai';
import Message from '../types/Message';

class NotificationType {
    className: string;
    icon: IconType;

    constructor(className: string, icon: IconType) {
      this.className = className;
      this.icon = icon;
    }
}

const NotificationTypes = [
    new NotificationType(
        'bg-red-400/60',
        AiFillWarning
    ),
    new NotificationType(
        'bg-green-400/60',
        AiFillCheckCircle
    ),
]

const Notification = ( props : any ) => {
    const [visible, setVisible] = useState(true);
    
    useEffect(() => {
        setVisible(true);

        const timer = setTimeout(() => {
            setVisible(false);
        }, 3000)

        return () => {
            clearTimeout(timer);
        }
    }, [props.message])

    var message: Message = props.message;
    var notificatonType = NotificationTypes[message.messageType];
    
    return (<>
        <button 
        onClick = {() => setVisible(false)}
        className={`w-full text-slate-100 hover:opacity-60 flex items-center gap-2 transition ease-in-out duration-300 transition-all
        ${(visible && message.value != '') ? 'mb-4 p-1 pl-3 pr-3' : 'h-0 scale-y-0 opacity-0 p-0 m-0'}
        ${props.className ? props.className : ''}
        ${notificatonType.className ? notificatonType.className : ''}`}>
            {<notificatonType.icon/>}
            {message.value}
        </button>
    </>);
}

export default Notification;
