const apiUrl = process.env.REACT_APP_API_URL as string;


// Kunde
export const getKunder = (page: number = 1, search: string, sortBy: string = '', isAsc: boolean, callback : Function) => {
    fetch(apiUrl + `/kunder?p=${page}&s=${search}&sb=${sortBy}&a=${isAsc ? 1 : 0}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const getKunde = (kunde_id: number, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde_id}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const putKunde = (dbId: number = 0, data: any, callback : Function) => {
    fetch(apiUrl + `/kunder/${dbId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postKunde = (data: any, callback : Function) => {
    fetch(apiUrl + `/kunder`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const deleteKunde = (dbId: number, callback : Function) => {
    fetch(apiUrl + `/kunder/${dbId}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const getDomener = (kunde: number, page: number = 1, search: string, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/domener?p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const getHendelser = (kunde: number, page: number = 1, search: string, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/hendelser?p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}
export const getNotater = (kunde: number, page: number = 1, search: string, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/notater?p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}


// Timebanker
export const getTimebanker = (kunde: number, page: number = 1, search: string, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/timebanker?p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}


// Inntekter
export const getInntekter = (kunde: number, page: number = 1, search: string, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/inntekter?p=${page}&s=${search}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const putInntekt = (kunde: number, uid: number, data: any, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/inntekter/${uid}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postInntekt = (kunde: number, data: any, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/inntekter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const deleteInntekt = (kunde: number, uid: number, callback : Function) => {
    fetch(apiUrl + `/kunder/${kunde}/inntekter/${uid}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}