import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDebounce } from 'use-debounce';

import { BiMath, BiCog } from 'react-icons/bi';
import { CgMathPlus } from 'react-icons/cg';
import { BsKey } from 'react-icons/bs';
import { FiServer } from 'react-icons/fi';

import PageContainer from '../components/PageContainer';
import LogoContainer from '../components/LogoContainer';
import Button from '../components/Button';
import UtgiftTable from '../components/Økonomi/UtgiftTable';
import TimebankTable from '../components/Økonomi/TimebankTable';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { formatDate, numberToMonth, numToLocale } from '../utils/util'
import { getUtgifter as fetchUtgifter, getOppsummering as fetchOppsummering, deleteUtgift } from '../api/okonomi';

var tomOppsummering: any = [];

for (let i = 1; i <= 12; i++) {
  tomOppsummering[i - 1] = {name: numberToMonth[i], Inntekter: 0, Utgifter: 0};
}

var tomFaktureringsData: any = [];

for (let i = 1; i <= 12; i++) {
  tomFaktureringsData[i - 1] = {
    kunder: [],
    total: 0
  };
}

const CustomLineTick = (props: any) => {
  const {x, y, payload} = props;

  return (<>
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#fff">
        <tspan textAnchor="middle" x="0">{payload.value}</tspan>
        <tspan textAnchor="middle" x="0" dy="23">{numToLocale(props.data[payload.index].Inntekter)}</tspan>
      </text>
    </g>
  </>);
}

function Økonomi() {
  const [driftData, setDriftData] = useState({'utgifter': [], 'pageCount': 1});
  const [driftSearch, setDriftSearch] = useState('');
  const [driftPage, setDriftPage] = useState(1);
  const [driftLoading, setDriftLoading] = useState(false);
  const [debouncedDriftSearch] = useDebounce(driftSearch, 300);

  const [lisenserData, setLisenserData] = useState({'utgifter': [], 'pageCount': 1});
  const [lisenserSearch, setLisenserSearch] = useState('');
  const [lisenserPage, setLisenserPage] = useState(1);
  const [lisenserLoading, setLisenserLoading] = useState(false);
  const [debouncedLisenserSearch] = useDebounce(lisenserSearch, 300);

  const [oppsummeringLoading, setOppsummeringLoading] = useState(true);
  const [oppsummeringData, setOppsummeringData] = useState(tomOppsummering);
  
  useEffect(() => {
    setOppsummeringLoading(true);

    fetchOppsummering((json: any) => {
      setOppsummeringData(json);
      setOppsummeringLoading(false);
    });
  }, []);

  const updateDriftsUtgifter = () => {
    setDriftLoading(true);

    fetchUtgifter(0, driftPage, debouncedDriftSearch, (json: any) => {
      setDriftData(json);
      setDriftLoading(false);
    });
  }

  useEffect(() => {
    updateDriftsUtgifter();
  }, [driftPage, debouncedDriftSearch]);

  const updateLisenserUtgifter = () => {
    setLisenserLoading(true);

    fetchUtgifter(1, lisenserPage, debouncedLisenserSearch, (json: any) => {
      setLisenserData(json);
      setLisenserLoading(false);
    });
  }

  useEffect(() => {
    updateLisenserUtgifter();
  }, [lisenserPage, debouncedLisenserSearch]);

  return (<>
    <Helmet>
      <title>Webtron - Økonomi</title>
    </Helmet>

    <PageContainer>
      <LogoContainer className='mb-8 min-h-108' titleClassName='mb-6' icon={BiMath} isLoading={oppsummeringLoading} title='Oppsummering'>
        <ResponsiveContainer width='100%' height={390}>
          <BarChart
            data={oppsummeringData}
            margin={{
              top: 5,
              right: 20,
              left: 20,
              bottom: 45,
            }}
          >
            <CartesianGrid strokeDasharray='3 3'/>
            <XAxis dataKey='Name' tick={<CustomLineTick data={oppsummeringData}/>}/>
            <YAxis tick={{ fill: '#fff' }}/>
            <Tooltip cursor={{fill: '#1e293b'}} contentStyle={{backgroundColor: '#334155', color: '#fff'}} formatter={(value: any) => value && `${numToLocale(value)}`}/>
            <Legend wrapperStyle={{top: 360, left: 0}}/>
            <Bar dataKey='Inntekter' fill='#90c9f5'/>
            <Bar dataKey='Profit' stackId='prof-utgi' fill='#5EB234'/>
            <Bar dataKey='Utgifter' stackId='prof-utgi' fill='#E8A530'/>
          </BarChart>
          </ResponsiveContainer>
      </LogoContainer>
      
      <TimebankTable className='mb-8'/>

      <UtgiftTable
        title='Drift'
        icon={FiServer}
        data={driftData}
        curPage={driftPage}
        searchValue={driftSearch}
        updateUtgifter={updateDriftsUtgifter}
        updateDrift={updateDriftsUtgifter}
        updateLisenser={updateLisenserUtgifter}
        setSearchVal={setDriftSearch}
        setPageCount={setDriftPage}
        isLoading={driftLoading}
      />

      <UtgiftTable
        title='Lisenser'
        icon={BsKey}
        className='mt-8'
        data={lisenserData}
        curPage={lisenserPage}
        searchValue={lisenserSearch}
        updateUtgifter={updateLisenserUtgifter}
        updateDrift={updateDriftsUtgifter}
        updateLisenser={updateLisenserUtgifter}
        setSearchVal={setLisenserSearch}
        setPageCount={setLisenserPage}
        isLoading={lisenserLoading}
        tabButtons={
          <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
        }
      />
    </PageContainer>
  </>);
}

export default Økonomi;
