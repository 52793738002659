import {MdOutlineNavigateBefore, MdOutlineNavigateNext, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight} from 'react-icons/md';

const Paginator = (paginatorProps: any) => {
    const NumberButtons = (props: any) => {
        var buttons: any = [];
    
        for (let i = 1; i < (props.pageCount + 1); i++) {
            buttons.push(<NumberButton key={`${i}_pagin`} callbackPage={props.callbackPage} toPage={i}/>);
        }
    
        return buttons;
    }

    const NumberButton = (props: any) => {
        return(
            <button
                onClick={() => {
                    if (props.isLoading)
                        return;
                    
                    paginatorProps.callbackPage(Math.min(Math.max(1, props.toPage), paginatorProps.pageCount))
                }}
                key={props.passedKey}
                className={`hover:bg-slate-600 pl-1.5 pr-1.5 text-slate-100 flex justify-center items-center${!props.children ? ' min-w-7' : ''}${(paginatorProps.curPage == props.toPage) && !props.ignoreActive ? ' bg-slate-600' : ''}`}>
                {props.children ? props.children : props.toPage}
            </button>
        )
    }

    return(
        <div className={`w-full border-t-2 border-slate-500/30 bg-slate-700 p-3 -mt-2 flex justify-center gap-1`}>
            <NumberButton passedKey={'full_prev'} toPage={1} ignoreActive={true}><MdKeyboardDoubleArrowLeft size={20}/></NumberButton>
            <NumberButton passedKey={'prev'} toPage={paginatorProps.curPage - 1} ignoreActive={true}><MdOutlineNavigateBefore size={20}/></NumberButton>
            <NumberButtons pageCount={paginatorProps.pageCount}/>
            <NumberButton passedKey={'full_next'} toPage={paginatorProps.curPage + 1} ignoreActive={true}><MdOutlineNavigateNext size={20}/></NumberButton>
            <NumberButton passedKey={'next'} toPage={paginatorProps.pageCount} ignoreActive={true}><MdKeyboardDoubleArrowRight size={20}/></NumberButton>
        </div>
    )
}

export default Paginator;