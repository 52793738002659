import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FiUsers } from 'react-icons/fi';
import { CgMathPlus } from 'react-icons/cg';
import { AiOutlineOrderedList } from 'react-icons/ai';

import PageContainer from '../components/PageContainer';
import Table, { TableEntry, SortButton } from '../components/Table';
import Button from '../components/Button';
import KundeModal from '../components/Kunder/KundeModal';

import { formatDate, numberToMonth, numToLocale } from '../utils/util';

import { getKunder as fetchKunder } from '../api/kunder';
import { useDebounce } from 'use-debounce';

const Kunder = () => {
  const [kundeData, setKundeData] = useState({'results': [], 'pageCount': 1});
  const [isLoading, setIsLoading] = useState(false);
  const [nyKundePopup, setNyKundePopup] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortData, setSortData] = useState({sortBy: '', isAsc: false});
  const [debouncedSearch] = useDebounce(searchValue, 300);

  const updateKunder = () => {
    setIsLoading(true);

    fetchKunder(curPage, debouncedSearch, sortData.sortBy, sortData.isAsc, (json: any) => {
      setKundeData(json);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    updateKunder();
  }, [curPage, debouncedSearch, sortData]);

  return (<>
    <Helmet>
      <title>Webtron - Kunder</title>
    </Helmet>
    
    {nyKundePopup && <KundeModal setOpen={setNyKundePopup} kundeData={{ny: true}} performUpdate={updateKunder}/>}

    <PageContainer>
      <Table
        title='Kundeliste'
        icon={AiOutlineOrderedList}
        showPaginator={true}
        curPage={curPage}
        searchValue={searchValue}
        onSearchChange={(e: any) => {
          setSearchValue(e.target.value);
        }}
        curSort={sortData.sortBy}
        onSort={(sort_str: string, asc: boolean) => {
          setSortData({
            sortBy: sort_str,
            isAsc: asc
          });
        }}
        tabButtons={
          <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setNyKundePopup(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
        }
        pageCount={kundeData['pageCount']}
        className={'flex flex-col min-h-96'}
        containerClassName={'grow'}
        isLoading={isLoading}
        callbackPage={setCurPage}
        header={[
          <SortButton sortBy='firma_navn'>Navn</SortButton>,
          <SortButton sortBy='kontakt_person'>Kontaktperson</SortButton>,
          <SortButton sortBy='telefon'>Tlf</SortButton>,
          <SortButton sortBy='epost'>Epost</SortButton>,
          <SortButton sortBy='faktura_maaned'>Fakturamåned</SortButton>,
          <SortButton sortBy='inntekt'>Inntekter</SortButton>,
          <SortButton sortBy='utgift'>Utgifter</SortButton>,
          <SortButton sortBy='kunde_siden'>Kunde siden</SortButton>,
          <SortButton sortBy='siste_redesign'>Siste redesign</SortButton>
        ]}>
          {kundeData['results'].map((kunde: any) => {
            var tlf = typeof kunde.telefon === 'number' ? kunde.telefon.toString() : '0';
            var tlfParsed  = tlf == 0 ? 'N/A' : `${tlf.substr(0, 3)} ${tlf.substr(3, 2)} ${tlf.substr(5, 3)}`;

            return <TableEntry
              key={`kun_side_${kunde.id}`}
              passedKey={`kun_side_${kunde.id}`}
              data={[
                <Link to={`/kunder/${kunde.id}`}>{kunde.firma_navn || 'N/A'}</Link>,
                kunde.kontakt_person == '' ? 'N/A' : kunde.kontakt_person,
                <a className={tlf == 0 ? 'pointer-events-none' : ''} href={`tel:${tlf}`}>{tlfParsed}</a>,
                <a href={`mailto:${kunde.epost}`}>{kunde.epost || 'N/A'}</a>,,
                numberToMonth[kunde.faktura_maaned] || 'N/A',
                `${numToLocale(kunde.inntekt)}`,
                `${numToLocale(kunde.utgift)}`,
                formatDate(kunde.kunde_siden),
                formatDate(kunde.siste_redesign)
              ]}/>
          })
        }
      </Table>
    </PageContainer>
  </>);
}

export default Kunder;
