const TextArea = (props: any) =>  {
    return(
        <div className={`flex items-center ${props.parentClass ? props.parentClass : ''}`}>
            {props.icon && 
                <div className='text-slate-100 bg-slate-400/30 h-8 w-8 flex items-center justify-center'>
                    <props.icon size={24}/>
                </div>
            }
            <textarea
                className={`resize-none p-0.5 pl-1.5 pr-1.5 w-full bg-slate-500 border-2 border-transparent focus:border-slate-400/50 text-slate-100 outline-none ${props.className ? props.className : ''}`}
                onChange={props.onChange}
                value={props.value}
                name={props.name}
                rows={4}
            >
                {props.children}
            </textarea>
        </div>
    )
}

export default TextArea;