export const getTilbud = (page: number = 1, search: string, sortBy: string = '', isAsc: boolean, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/tilbud?p=${page}&s=${search}&sb=${sortBy}&a=${isAsc ? 1 : 0}`)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const deleteTilbud = (dbId: number, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/tilbud/${dbId}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const putTilbud = (dbId: number = 0, data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/tilbud/${dbId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postTilbud = (data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/tilbud`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}