import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import ContextMenu, { ContextButton } from '../ContextMenu';
import DomeneModal from './DomeneModal';
import Table, { TableEntry } from '../Table';
import Button from '../Button';

import { numToLocale } from '../../utils/util';
import { getDomener } from '../../api/kunder';
import { deleteDomene } from '../../api/domener';

import { CgMathPlus } from 'react-icons/cg';
import { AiOutlineCloud } from 'react-icons/ai';

const DomeneTable = (props: any) => {
    const [selectedDomene, setSelectedDomene] = useState(-1);
    const [modalDomeneData, setModalDomeneData] = useState({});
    const [domeneData, setDomeneData] = useState<any>({results: []});

    const [curPage, setCurPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [debouncedSearch] = useDebounce(searchVal, 300);
    const { kundeId } = useParams() as any;

    const updateTable = () => {
      setIsLoading(true);

      getDomener(kundeId, curPage, debouncedSearch, (json: any) => {
        setDomeneData(json);
        setIsLoading(false);
      });

      props.getKunde();
    }

    useEffect(() => {
      updateTable();
    }, [debouncedSearch, curPage]);
  
    return (<>
      <div className={props.containerClassName}>
        <ContextMenu isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
          <ContextButton
            onClick={() => {setModalDomeneData(domeneData.results[selectedDomene]);setIsEditModalOpen(true)}}
          >Rediger</ContextButton>
          <ContextButton
            onClick={() => {deleteDomene(domeneData.results[selectedDomene].id, updateTable)}}
          >Slett</ContextButton>
        </ContextMenu>
    
        {isEditModalOpen && <DomeneModal setOpen={setIsEditModalOpen} updateTable={updateTable} domeneData={modalDomeneData}/>}
    
        <Table
          title={'Domener'}
          icon={AiOutlineCloud}
          iconClassName={props.iconClassName}
          className={`min-h-96 ${props.className ? props.className : ''}` }
          showPaginator={domeneData['pageCount'] > 1}
          curPage={curPage}
          searchValue={searchVal}
          isEmpty={!domeneData.results || domeneData.results.length <= 0}
          onSearchChange={(e: any) => {
            setSearchVal(e.target.value);
          }}
          tabButtons={
              <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setModalDomeneData({'ny': true, 'kunde_id': kundeId}); setIsEditModalOpen(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
          }
          pageCount={domeneData['pageCount']}
          containerClassName={'grow'}
          isLoading={isLoading}
          callbackPage={setCurPage}
          header={['Domene', 'Pris', 'Applikasjon', 'Merknader']}>
            {domeneData.results.map((domene: any, index: number) => {
              return <TableEntry
                callbackContextMenu={() => {setSelectedDomene(index); setIsDropdownOpen(true)}}
                key={`kun_dmn_${domene.id}`}
                passedKey={`kun_dmn_${domene.id}`}
                data={[
                  <a href={`https://${domene.navn}`} target='_'>{domene.navn}</a>,
                  numToLocale(domene.pris),
                  'N/A',
                  domene.merknader || 'N/A'
                ]}/>
            })}
        </Table>
      </div>
    </>);
}

export default DomeneTable;