import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import ContextMenu, { ContextButton } from '../ContextMenu';
import HendelseModal from './HendelseModal';
import Table, { TableEntry } from '../Table';
import Button from '../Button';
import Initial from '../Initial';
import TableTooltip from '../Table/Tooltip';

import { formatDate } from '../../utils/util';
import { getHendelser } from '../../api/kunder';
import { deleteHendelse } from '../../api/hendelser';

import { CgMathPlus } from 'react-icons/cg';
import { LiaToolsSolid } from 'react-icons/lia';

const HendelseTable = (props: any) => {
    const [selectedHendelse, setSelectedHendelse] = useState(-1);
    const [modalHendelseData, setModalHendelseData] = useState({});
    const [hendelseData, setHendelseData] = useState<any>({results: []});

    const [curPage, setCurPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [debouncedSearch] = useDebounce(searchVal, 300);
    const { kundeId } = useParams() as any;


    const updateTable = () => {
      setIsLoading(true);

      getHendelser(kundeId, curPage, debouncedSearch, (json: any) => {
        setHendelseData(json);
        setIsLoading(false);
      });

      props.getKunde();
    }

    useEffect(() => {
      updateTable();
    }, [debouncedSearch, curPage]);
  
    return (<>
      <div className={props.containerClassName}>
        <ContextMenu isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
          <ContextButton
            onClick={() => {setModalHendelseData(hendelseData.results[selectedHendelse]);setIsEditModalOpen(true)}}
          >Rediger</ContextButton>
          <ContextButton
            onClick={() => {deleteHendelse(hendelseData.results[selectedHendelse].id, updateTable)}}
            >Slett</ContextButton>
        </ContextMenu>
    
        {isEditModalOpen && <HendelseModal setOpen={setIsEditModalOpen} updateTable={updateTable} hendelseData={modalHendelseData}/>}
    
        <Table
          title={'Teknisk'}
          icon={LiaToolsSolid}
          iconClassName={'text-blue-400'}
          className={`min-h-96 ${props.className ? props.className : ''}` }
          showPaginator={hendelseData['pageCount'] > 1}
          curPage={curPage}
          searchValue={searchVal}
          onSearchChange={(e: any) => {
            setSearchVal(e.target.value);
          }}
          tabButtons={
              <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setModalHendelseData({'ny': true, 'kunde_id': kundeId}); setIsEditModalOpen(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
          }
          pageCount={hendelseData['pageCount']}
          isLoading={isLoading}
          isEmpty={!hendelseData.results || hendelseData.results.length <= 0}
          callbackPage={setCurPage}
          header={[['', 'w-12'], ['Dato', 'w-32'], 'Tekst']}>
            {hendelseData.results.map((hendelse: any, index: number) => {
              return <TableEntry
                callbackContextMenu={() => {setSelectedHendelse(index); setIsDropdownOpen(true)}}
                key={`kun_hnd_${hendelse.id}`}
                passedKey={`kun_hnd_${hendelse.id}`}
                data={[
                  <Initial>{hendelseData.initialer[hendelse.bruker_id]}</Initial>,
                  formatDate(hendelse.dato),
                  <TableTooltip>{hendelse.tekst}</TableTooltip>
                ]}/>
            })}
        </Table>
      </div>
    </>);
}

export default HendelseTable;