import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { BiLinkAlt } from 'react-icons/bi';

import PageContainer from '../components/PageContainer';
import Table, { TableEntry, SortButton } from '../components/Table';

import { useDebounce } from 'use-debounce';

import { numToLocale } from '../utils/util';
import { getDomener as fetchDomener} from '../api/domener'

function Domener() {
  const [domeneData, setDomeneData] = useState({'results': [], 'pageCount': 1});
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [searchValue, SetSearchValue] = useState('');
  const [sortData, setSortData] = useState({sortBy: '', isAsc: false});

  const [debouncedSearch] = useDebounce(searchValue, 300);

  useEffect(() => {
    setIsLoading(true);

    fetchDomener(curPage, debouncedSearch, sortData.sortBy, sortData.isAsc, (json: any) => {
      setDomeneData(json);
      setIsLoading(false);
    });
  }, [curPage, debouncedSearch, sortData]);

  return (<>
    <Helmet>
      <title>Webtron - Domener</title>
    </Helmet>

    <PageContainer>
      <Table
          title="Oversikt"
          icon={BiLinkAlt}
          showPaginator={true}
          curPage={curPage}
          searchValue={searchValue}
          onSearchChange={(e: any) => {
            SetSearchValue(e.target.value);
          }}
          curSort={sortData.sortBy}
          onSort={(sort_str: string, asc: boolean) => {
            setSortData({
              sortBy: sort_str,
              isAsc: asc
            });
          }}
          pageCount={domeneData['pageCount']}
          className={'flex flex-col min-h-96'}
          containerClassName={'grow'}
          isLoading={isLoading}
          callbackPage={setCurPage}
          header={[
            <SortButton sortBy='navn'>Domene</SortButton>,
            // "Applikasjon",
            // "System",
            <SortButton sortBy='pris'>Pris</SortButton>,
            <SortButton sortBy='merknader'>Merknader</SortButton>,
            // "Status",
            <SortButton sortBy='firma_navn'>Kunde</SortButton>
            ]}>
            {domeneData['results'].map((domene: any) => {
              return <TableEntry
                key={`dom_${domene.id}`}
                passedKey={`dom_${domene.id}`}
                data={[
                  <a href={`https://${domene.navn}`} target='_'>{domene.navn}</a>,
                  // 'N/A',
                  // 'N/A',
                  `${numToLocale(domene.pris)}`,
                  domene.merknader === '' ? 'N/A' : domene.merknader,
                  // domene.status,
                  <Link to={`/kunder/${domene.kunde_id}`}>{domene.firma_navn}</Link>
                ]}/>
            })
          }
        </Table>
    </PageContainer>
  </>);
}

export default Domener;
