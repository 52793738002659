import LogoContainer from './LogoContainer';
import { CgClose } from 'react-icons/cg';

const Modal = (props: any) => {
    return(<>
        <div className='fixed left-0 top-0 w-full h-full p-4 z-20 bg-gray-900/90 flex justify-center items-center'>
            <button className={'absolute left-0 top-0 w-full h-full'} onClick={props.onClose}/>
            <div className={`container relative text-white ${props.sizeClass ? props.sizeClass : 'max-w-xl'}`}>
                <LogoContainer
                    titleClassName='text-md'
                    className={props.className}
                    title={props.title}
                    noHeader={props.noHeader}
                    titleComponents={[
                        <button key={`${props.title}_close`} onClick={props.onClose}>
                            <CgClose className='text-white ml-auto mb-3 text-gray-100 hover:opacity-50' size={28}/>
                        </button>
                    ].concat(props.titleComponents)}
                    icon={props.icon}
                >
                    {!props.noHeader && <div className='border-b-2 border-slate-500/30 mb-3'/>}
                    {props.children}
                </LogoContainer>
            </div>
        </div>
    </>);
};

export default Modal;