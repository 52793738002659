import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Modal from '../../Modal';
import Input from '../../Input';
import Dropdown from '../../Dropdown';
import Button from '../../Button';

import { BiPencil, BiPlus } from 'react-icons/bi';
import { numberToMonth, monthToNumber } from '../../../utils/util';

import { postInntekt, putInntekt } from '../../../api/kunder'

const dropdownOptions = ['Ingen valgt', 'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

const InntektModal = (props: any) => {
    const [tjenesteNavn, setTjenesteNavn] = useState('');
    const [tjenestePris, setTjenestePris] = useState(0);
    const [fakturaMaaned, setFakturaMaaned] = useState('');
    const { kundeId } = useParams() as any;


    const sendUpdate = (props: any) => {
        const parsed: any = {
            tjeneste: tjenesteNavn,
            pris: tjenestePris,
            faktura_maaned: monthToNumber[fakturaMaaned] || 0,
        }

        var modified = false;

        for (let key in parsed) {
            if (props.inntektData[key] != parsed[key])
            {
                modified = true;
                break;
            }
        };

        if (!modified)
            return props.setOpen(false);

        const onFinish = () => {
            props.setOpen(false)
            props.updateTable();
        }

        if (props.inntektData.ny) {
            postInntekt(kundeId, parsed, onFinish);
        } else {
            putInntekt(kundeId, props.inntektData.id, parsed, onFinish);
        }


    }

    useEffect(() => {
        const data = props.inntektData;

        setTjenesteNavn(data.tjeneste);
        setTjenestePris(data.pris);
        setFakturaMaaned(dropdownOptions[data.faktura_maaned] || dropdownOptions[0])

    }, [props.inntektData])
  
    return(<>
      <Modal
        title={props.inntektData.ny ? 'Ny inntekt' : 'Rediger inntekt'} 
        icon={props.inntektData.ny ? BiPlus : BiPencil} 
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <div className='grid grid-cols-1 md:grid-cols-8 gap-6'>
                <div className='flex flex-col col-span-8'>
                    <h3 className='mb-1'>Tjeneste</h3>
                    <Input value={tjenesteNavn} onChange={(e: any) => setTjenesteNavn(e.target.value)} type='text'/>
                </div>

                <div className='flex flex-col col-span-4'>
                    <h3 className='mb-1'>Pris</h3>
                    <Input value={tjenestePris} onChange={(e: any) => setTjenestePris(e.target.value)} type='number'/>
                </div>

                <div className='flex flex-col col-span-4'>
                    <h3 className='mb-1'>Fakturamåned</h3>
                    <Dropdown value={fakturaMaaned} options={dropdownOptions} setValue={(val: any) => setFakturaMaaned(val)}/>
                </div>
            </div>

            <Button
                className='mt-6'
                type="submit"
            >
                {props.inntektData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default InntektModal;