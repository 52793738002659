import { ImSpinner9 } from 'react-icons/im';

const Button = ( props : any ) => {
  return (     
    <button
      onClick={props.onClick}
      type={props.type}
      className={`bg-blue-400 text-blue-100 hover:opacity-60 flex items-center gap-2 ${props.customSpacing ? props.customSpacing : 'mt-4 p-1 pl-3 pr-3'} ${props.className ? props.className : ''}`}>
        {props.children}
        {props.isLoading && <ImSpinner9 className='animate-spin h-5 w-5 text-blue-100' size={18}/>}
    </button>
  );
}

export default Button;
