export const deleteHendelse = (dbId: number, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/hendelser/${dbId}`, { method: 'DELETE' })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const putHendelse = (dbId: number = 0, data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/hendelser/${dbId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}

export const postHendelse = (data: any, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + `/hendelser`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(response => callback(response))
    .catch(error => console.error(error));
}