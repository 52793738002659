import { useEffect, useState } from 'react';
import Modal from '../Modal';
import Input from '../Input';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Button from '../Button';

import { BiPencil, BiPlus } from 'react-icons/bi';

import { numberToCurrency, currencyToNumber, createDate } from '../../utils/util';
import { putUtgift, postUtgift } from '../../api/okonomi'

const numberToType: any = {
    0: 'Drift',
    1: 'Lisens'
};

const typeToNumber : any = {
    'Drift': 0,
    'Lisens': 1,
};

const UtgiftModal = (props: any) => {
    const [utgiftNavn, setUtgiftNavn] = useState('');
    const [utgiftType, setUtgiftType] = useState(0);
    const [utgiftPris, setUtgiftPris] = useState(0);
    const [betalingsFrekvens, setBetalingsFrekvens] = useState(0);
    const [utgiftBeskrivelse, setUtgiftBeskrivelse] = useState('');
    const [sisteBetaling, setSisteBetaling] = useState(new Date());
    const [nesteBetaling, setNesteBetaling] = useState(new Date());

    const [isLopende, setIsLopende] = useState(false);
    const [curValuta, setCurValuta] = useState('NOK');

    const sendUpdate = (props: any) => {
        const parsed: any = {
            til: utgiftNavn,
            fritekst: utgiftBeskrivelse,
            pris: utgiftPris,
            valuta: currencyToNumber[curValuta],
            betalings_frekvens: betalingsFrekvens,
            loopende: isLopende,
            neste_betaling: nesteBetaling ? Math.floor(nesteBetaling.getTime() / 1000) : null,
            sist_betalt: sisteBetaling ? Math.floor(sisteBetaling.getTime() / 1000) : null,
            type: typeToNumber[utgiftType],
            kunde_id: 0,
        }

        var modified = false;

        for (let key in parsed) {
            if (props.utgiftData[key] != parsed[key])
            {
                modified = true;
                break;
            }
        };

        if (!modified)
            return props.setOpen(false);

        const onFinished = () => {
            props.setOpen(false);

            switch(props.utgiftData.type) {
                case 0:
                    props.updateDrift();
                    break;
                default:
                    props.updateLisenser();
            }

            if (props.utgiftData == parsed.type)
                return;

            switch(parsed.type) {
                case 0:
                    props.updateDrift();
                    break;
                default:
                    props.updateLisenser();
            }
        }

        if (props.utgiftData.ny) {
            postUtgift(parsed, onFinished);
        } else {
            putUtgift(props.utgiftData.id, parsed, onFinished);
        }
    }

    useEffect(() => {
        const data = props.utgiftData;

        setUtgiftNavn(data.til);
        setUtgiftType(numberToType[data.type]);
        setUtgiftPris(data.pris);
        setUtgiftBeskrivelse(data.fritekst);
        setBetalingsFrekvens(data.betalings_frekvens);
        setSisteBetaling(createDate(data.sist_betalt));
        setNesteBetaling(createDate(data.neste_betaling));
        setCurValuta(numberToCurrency[data.valuta]);
        setIsLopende(data.loopende);
    }, [props.utgiftData])
  
    return(<>
      <Modal
        title={props.utgiftData.ny ? 'Ny utgift' : 'Rediger utgift'} 
        icon={props.utgiftData.ny ? BiPlus : BiPencil} 
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <div className='grid grid-cols-1 md:grid-cols-8 gap-6'>
                <div className='flex flex-col col-span-6'>
                    <h3 className='mb-1'>Navn</h3>
                    <Input value={utgiftNavn} onChange={(e: any) => setUtgiftNavn(e.target.value)} type='text'/>
                </div>

                <div className='flex flex-col col-span-2'>
                    <h3 className='mb-1'>Type</h3>
                    <Dropdown options={['Drift', 'Lisens']} value={utgiftType} setValue={setUtgiftType}/>
                </div>
            </div>

            

            <h3 className='mt-4 mb-1'>Beskrivelse</h3>
            <Input value={utgiftBeskrivelse} onChange={(e: any) => setUtgiftBeskrivelse(e.target.value)} type='text'/>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                <div>
                    <h3 className='mt-4 mb-1'>Kostnad</h3>
                    <div className='flex'>
                        <Dropdown className='bg-slate-600' options={['NOK', 'USD', 'EUR']} value={curValuta} setValue={setCurValuta}/>
                        <Input type='number' value={utgiftPris} onChange={(e: any) => setUtgiftPris(e.target.value)} parentClass='w-full'/>
                    </div>
                </div>
        
                <div className='flex flex-col'>
                    <h3 className='mt-4 mb-1'>Betalingsfrekvens</h3>
                    <Input type='number' value={betalingsFrekvens} onChange={(e: any) => setBetalingsFrekvens(e.target.value)} parentClass='w-full'/>
                </div>

                <div>
                    <h3 className='mt-4 mb-1'>Løpende</h3>
                    <Checkbox isChecked={isLopende} onClick={(e: any) => {e.preventDefault();setIsLopende(!isLopende)}}/>
                </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div className='flex flex-col'>
                    <h3 className='mt-4 mb-1'>Siste betaling</h3>
                    <DatePicker isClearable showIcon selected={sisteBetaling} setSelected={setSisteBetaling} />
                </div>

                <div className='flex flex-col'>
                    <h3 className='mt-4 mb-1'>Neste betaling</h3>
                    <DatePicker isClearable showIcon selected={nesteBetaling} setSelected={setNesteBetaling} />
                </div>
            </div>

            <Button
                className='mt-6'
                type="submit"
            >
                {props.utgiftData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default UtgiftModal;