import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import Table, { TableEntry } from '../Table';
import TableTooltip from '../Table/Tooltip';

import { formatDate, numToLocale } from '../../utils/util';
import { getTimebanker } from '../../api/okonomi';
import { BsPiggyBank } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const TimebankTable = (props: any) => {
    const [timebankData, setTimebankData] = useState<any>({results: []});

    const [timerTilgode, setTimerTilgode] = useState<any>(0);
    const [curPage, setCurPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [curManageTimebankModal, setCurManageTimebankModal] = useState();
    const [searchVal, setSearchVal] = useState('');
    const [debouncedSearch] = useDebounce(searchVal, 300);

    const updateTable = () => {
      setIsLoading(true);

      getTimebanker(curPage, debouncedSearch, (json: any) => {
        json.results.sort(function(a: any, b: any){
          return (b.timer - b.timerBrukt) - (a.timer - a.timerBrukt);
        })
        
        setTimebankData(json);
        setIsLoading(false);

        let timer = 0;

        json.results.map((timebank: any, index: number) => {
          timer += timebank.timer - timebank.timerBrukt;
        });

        setTimerTilgode(timer.toFixed(1).replace(/\.0$/, ''));
      });
    }

    useEffect(() => {
      updateTable();
    }, [debouncedSearch, curPage]);

    useEffect(() => {
      timebankData.results.forEach((timebank: any) => {
        if (curManageTimebankModal && curManageTimebankModal['id'] == timebank.id) {
          setCurManageTimebankModal(timebank);
        }
      });
    }, [timebankData])
  
    return (<>
      <div className={props.containerClassName}>
    
        <Table
          title={`Timebank - Timer til gode: ${timerTilgode}`}
          icon={BsPiggyBank}
          iconClassName={props.iconClassName}
          className={`min-h-96 ${props.className ? props.className : ''}`}
          showPaginator={timebankData['pageCount'] > 1}
          curPage={curPage}
          searchValue={searchVal}
          onSearchChange={(e: any) => {
            setSearchVal(e.target.value);
          }}
          pageCount={timebankData['pageCount']}
          isLoading={isLoading}
          isEmpty={!timebankData.results || timebankData.results.length <= 0}
          callbackPage={setCurPage}
          header={[['Dato', 'w-40'], 'Kunde', 'Beskrivelse', 'Timer', 'Timer til gode']}>
            {timebankData.results.map((timebank: any, index: number) => {
              const timerTilgode: any = (timebank.timer - timebank.timerBrukt);
              
              return <TableEntry
                key={`kun_hnd_${timebank.id}`}
                onClick={() => {setCurManageTimebankModal(timebank);}}
                passedKey={`kun_hnd_${timebank.id}`}
                className={(parseInt(timerTilgode) <= 0) ? `relative after:z-30 after:left-2 after:right-2 after:content after:absolute after:h-0.5 after:top-1/2 after:-translate-y-1/2 after:bg-red-500/80 opacity-70 order-10` : ''}
                data={[
                  formatDate(timebank.dato),
                  <Link to={`/kunder/${timebank.kunde_id}`}>{timebank.kunde}</Link>,
                  <TableTooltip>{timebank.beskrivelse}</TableTooltip>,
                  timebank.timer,
                  timerTilgode.toFixed(1).replace(/\.0$/, '')
                ]}/>
            })}
        </Table>
      </div>
    </>);
}

export default TimebankTable;