import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import Modal from '../../../Modal';
import ContextMenu, { ContextButton } from '../../../ContextMenu';
import Table, { TableEntry } from '../../../Table';
import Button from '../../../Button';
import Initial from '../../../Initial';
import TableTooltip from '../../../Table/Tooltip';
import TimebankManageModal from './TimebankManageModal';

import { formatDate, numToLocale } from '../../../../utils/util';
import { getTimebankLinjer, deleteTimebankLinje } from '../../../../api/timebanker';

import { CgMathPlus, CgClose } from 'react-icons/cg';
import { BiSolidHourglass } from 'react-icons/bi';

const TimebankManageTable = (props: any) => {
    const [selectedTimebank, setSelectedTimebank] = useState(-1);
    const [modalTimebankData, setModalTimebankData] = useState({});
    const [timebankData, setTimebankData] = useState<any>({results: []});

    const [curPage, setCurPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [debouncedSearch] = useDebounce(searchVal, 300);

    const updateTable = () => {
      setIsLoading(true);

      getTimebankLinjer(props.curTimebank.id, curPage, debouncedSearch, (json: any) => {
        setTimebankData(json);
        setIsLoading(false);
      });
    }

    useEffect(() => {
      updateTable();
    }, [debouncedSearch, curPage]);  
    
    return (<>
      <Modal
          icon={BiSolidHourglass} 
          onClose={() => {props.setOpen(null)}}
          className='!p-0'
          noHeader={true}
          sizeClass=' '
      >
        <div className={props.containerClassName}>
          <ContextMenu ignoreOffset={true} isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
            <ContextButton
              onClick={() => {setModalTimebankData(timebankData.results[selectedTimebank]);setIsEditModalOpen(true)}}
            >Rediger</ContextButton>
            <ContextButton
              onClick={() => {deleteTimebankLinje(timebankData.results[selectedTimebank].id, () => {updateTable();props.updateTimebankerTable();})}}
              >Slett</ContextButton>
          </ContextMenu>
      
          {isEditModalOpen && <TimebankManageModal setOpen={setIsEditModalOpen} updateTimebankerTable={props.updateTimebankerTable} updateTable={updateTable} timebankData={modalTimebankData} curTimebank={props.curTimebank}/>}

          <Table
            title={`Timeregistrering - Brukt ${(props.curTimebank.timerBrukt || 0).toFixed(1).replace(/\.0$/, '') || 0} av ${props.curTimebank.timer} timer`}
            icon={BiSolidHourglass}
            iconClassName={props.iconClassName}
            className={`min-h-96 ${props.className ? props.className : ''}` }
            showPaginator={timebankData['pageCount'] > 1}
            curPage={curPage}
            searchValue={searchVal}
            onSearchChange={(e: any) => {
              setSearchVal(e.target.value);
            }}
            tabButtons={
                <>
                <button className='order-3' key={`timeregistrering_close`} onClick={() => {props.setOpen(null)}}>
                  <CgClose className='ml-auto text-gray-100 hover:opacity-50' size={28}/>
                </button>
                <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setModalTimebankData({'ny': true}); setIsEditModalOpen(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button></>
            }
            tabButtonsClass='w-full justify-end'
            pageCount={timebankData['pageCount']}
            isLoading={isLoading}
            isEmpty={!timebankData.results || timebankData.results.length <= 0}
            callbackPage={setCurPage}
            header={[['', 'w-12'], ['Dato', 'w-40'], 'Beskrivelse', 'Timer']}>
              {timebankData.results.map((timebank: any, index: number) => {
                return <TableEntry
                  callbackContextMenu={() => {setSelectedTimebank(index); setIsDropdownOpen(true)}}
                  key={`kun_hnd_${timebank.id}`}
                  onClick={() => { }}
                  passedKey={`kun_hnd_${timebank.id}`}
                  data={[
                    <Initial>{timebankData.initialer[timebank.bruker_id]}</Initial>,
                    formatDate(timebank.dato),
                    <TableTooltip>{timebank.beskrivelse || 'N/A'}</TableTooltip>,
                    timebank.timer
                  ]}/>
              })}
          </Table>
        </div>
      </Modal>
    </>);
}

export default TimebankManageTable;