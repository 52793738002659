import { useState } from 'react';
import logo from '../../assets/svg/logo-liten.svg';
import { Link } from 'react-router-dom';
import NavLink from './NavLink';
import { MdOutlineDashboard, MdLogout, MdOutlineSell } from 'react-icons/md';
import { FiUsers, FiServer } from 'react-icons/fi';
import { AiOutlineCloud } from 'react-icons/ai';
import { BiLineChart } from 'react-icons/bi';
import { GoTasklist } from "react-icons/go";
import { ImSpinner9 } from 'react-icons/im';

import { useDispatch } from 'react-redux';
import { set as setSession } from '../../features/session'

import { logout } from '../../api/auth';

const Navbar = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <div className='p-8 pt-2 pb-2 bg-slate-800 text-white relative'>
      <div className='flex justify-between gap-12 ml-auto mr-auto'>
        <Link to='/'><img src={logo} className='w-8 h-8 hover:opacity-60 transition ease-in-out duration-150' alt='logo' /></Link>
        <div className='flex items-center -mt-2 -mb-2 gap-5'>
            <NavLink to='/' icon={MdOutlineDashboard}>Dashboard</NavLink>
            <NavLink to='/kunder' icon={FiUsers}>Kunder</NavLink>
            <NavLink to='/økonomi' icon={BiLineChart}>Økonomi</NavLink>
            <NavLink to='/salg' icon={MdOutlineSell}>Salg</NavLink>
            {/* <NavLink to='/applikasjoner' icon={FiServer}>Applikasjoner</NavLink> */}
            <NavLink to='/domener' icon={AiOutlineCloud}>Domener</NavLink>
            {/* <NavLink to='/prosjekter' icon={GoTasklist}>Prosjekter</NavLink> */}
        </div>
        <div className='flex justify-center'>
          <button onClick={ () => {
            setLoading(true);

            logout((json : any) => {
              dispatch(setSession(json));
            });
          }} className='hover:opacity-50'>
            {!loading ? <MdLogout size={24}/> : <ImSpinner9 className='animate-spin' size={28} /> }
          </button>
        </div>
      </div>
    </div>
  );
};
  
export default Navbar;