import { useState, useEffect, useRef } from 'react';

const Dropdown = (props: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const ownElement = useRef(null);

    useEffect(() => {
        function onClicked(event: any) {
            if(ownElement.current != event.target)
                setIsOpen(false);
        }

        function onRightClicked(e: any) {
            if (ownElement.current != e.target && e.which == 3)
                setIsOpen(false);
        }

        window.addEventListener('click', onClicked)
        window.addEventListener('mousedown', onRightClicked);
        
        return () => {
            window.removeEventListener('click', onClicked)
            window.removeEventListener('mousedown', onRightClicked);
        }
    }, [])

    return(<>
        <div className={`relative ${isOpen ? 'z-20' : ''}`}>
            <button
                ref={ownElement}
                className={`bg-slate-500 w-full text-left border-2 border-transparent text-slate-100 h-8 outline-none pl-2 pr-2${isOpen ? ' border-slate-400/50 bg-slate-400' : ''} ${props.className ? props.className : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}
            >
                {props.value}
            </button>
            {isOpen && <>
                <div className='absolute w-full bg-slate-500 border-slate-400/50 text-slate-100 border-2 left-0 bottom-100'>
                    {props.options.map((option: any) => {
                        if (option == props.value)
                            return;

                        return <button key={`dd_${option}`} onClick={(e) => {e.preventDefault(); props.setValue(option); setIsOpen(false)}} className='text-left hover:bg-slate-400 w-full pl-2 pr-2'>{option}</button>
                    })}
                </div>
            </>}
        </div>
    </>);
}

export default Dropdown;