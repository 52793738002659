import { useEffect, useState } from 'react';
import Modal from '../Modal';
import Input from '../Input';
import TextArea from '../TextArea';
import Button from '../Button';
import DatePicker from '../DatePicker';
import { BiPencil, BiPlus } from 'react-icons/bi';

import { createDate } from '../../utils/util';
import { putHendelse, postHendelse } from '../../api/hendelser'

const HendelseModal = (props: any) => {
    const [hendelseDato, setHendelseDato] = useState(new Date());
    const [hendelseTekst, setHendelseTekst] = useState('');

    const sendUpdate = (props: any) => {
        const parsed: any = {
            dato: hendelseDato ? Math.floor(hendelseDato.getTime() / 1000) : null,
            tekst: hendelseTekst || '',
            kunde_id: props.hendelseData.kunde_id
        }

        var modified = false;

        for (let key in parsed) {
            if (props.hendelseData[key] != parsed[key])
            {
                modified = true;
                break;
            }
        };

        if (!modified)
            return props.setOpen(false);

        const onFinished = () => {
            props.setOpen(false);
            props.updateTable();
        }

        if (props.hendelseData.ny) {
            postHendelse(parsed, onFinished);
        } else {
            putHendelse(props.hendelseData.id, parsed, onFinished);
        }
    }

    useEffect(() => {
        setHendelseDato(createDate(props.hendelseData.dato, true));
        setHendelseTekst(props.hendelseData.tekst);
    }, [props.hendelseData])
  
    return(<>
      <Modal
        title={props.hendelseData.ny ? 'Ny hendelse' : 'Rediger hendelse'} 
        icon={props.hendelseData.ny ? BiPlus : BiPencil} 
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <div className='flex flex-col'>
                <h3 className='mb-1'>Dato</h3>
                <DatePicker isClearable showIcon selected={hendelseDato} setSelected={setHendelseDato} />
            </div>

            <h3 className='mt-4 mb-1'>Tekst</h3>
            <TextArea value={hendelseTekst} onChange={(e: any) => setHendelseTekst(e.target.value)} type='text'/>
            
            <Button
                className='mt-6'
                type="submit"
            >
                {props.hendelseData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default HendelseModal;