import { useState, useEffect, useRef } from 'react';
import useMousePosition from '../utils/useMousePosition';

const ContextMenu = ( props: any ) => {
    const mousePosition = useMousePosition();
    const [curPos, setCurPos] = useState({x: 0, y: 0});
    const ownElement = useRef<HTMLInputElement>(null);

    useEffect(() => {
        function onClicked(event: any) {
            if(ownElement.current != event.target)
                props.setIsOpen(false);
        }

        function onRightClicked(e: any) {
            if (ownElement.current != e.target && e.which == 3)
                props.setIsOpen(false);
        }

        window.addEventListener('click', onClicked)
        window.addEventListener('mousedown', onRightClicked);
        
        return () => {
            window.removeEventListener('click', onClicked)
            window.removeEventListener('mousedown', onRightClicked);
        }
    }, [])

    useEffect(() => {
        if (!props.isOpen)
            return;

        var newPos = mousePosition;
        
        if (!props.ignoreOffset) {
            newPos.x += window.scrollX;
            newPos.y += window.scrollY - 15;
        }

        setCurPos(newPos);
    }, [props.isOpen]);

    return (
        <div
            className={`bg-slate-400/40 bg-blur flex flex-col p-2.5 gap-1.5 z-20 backdrop-blur-md transition
            ${!props.isOpen ? 'opacity-0 pointer-events-none scale-y-0' : 'opacity-100 scale-y-100'}
            ${props.ignoreOffset ? 'fixed' : 'absolute'}
            `}
            style={{left: curPos.x, top: curPos.y}}
            ref={ownElement}
        >
            {props.children}
        </div>
    );
}

export const ContextButton = ( props: any ) => {
    return(
        <>
            <button
                className='bg-slate-600 pl-5 pr-4 text-white hover:opacity-80'
                onClick={props.onClick}
            >
                {props.children}
            </button>

        </>
    );
} 

export default ContextMenu;