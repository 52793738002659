import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LogoCard from '../components/LogoCard'
import LogoContainer from '../components/LogoContainer'
import Initial from '../components/Initial';

import { FiUsers } from 'react-icons/fi';
import { AiOutlineCloud } from 'react-icons/ai';
import { LiaToolsSolid } from 'react-icons/lia'
import { BiLineChart, BiLineChartDown } from 'react-icons/bi';
import { BsPiggyBank } from 'react-icons/bs';
import { ImCalendar } from 'react-icons/im';
import { PiNotepadLight } from 'react-icons/pi';
import { MdOutlineLocalOffer } from 'react-icons/md';

import PageContainer from '../components/PageContainer';
import Table, { TableEntry } from '../components/Table';
import TableTooltip from '../components/Table/Tooltip';
import Button from '../components/Button';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { useSelector, useDispatch } from 'react-redux';
import { set as setDashboard } from '../features/dashboard';
import { formatDateShort, numToLocale, numberToMonth, formatDate } from '../utils/util';

import getDashboard from '../api/dashboard/get';

function Dashboard() {
  const dashboardData = useSelector((state : any) => state.dashboard.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getDashboard((json: any) => {
      if (json.messageType == 0)
        return;

      dispatch(setDashboard(json));
    });
  }, []);

  return (<>
    <Helmet>
      <title>Webtron - Dashboard</title>
    </Helmet>

    <PageContainer>
      <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 gap-6'>
        <LogoCard icon={FiUsers} title='Antall kunder'>{dashboardData.kundeCount}</LogoCard>
        <LogoCard icon={AiOutlineCloud} title='Antall domener'>{dashboardData.domeneCount}</LogoCard>
        <LogoCard icon={BiLineChart} title='Årsinntekt'>{numToLocale(dashboardData.aarsinntekt)}</LogoCard>
        <LogoCard icon={BiLineChartDown} title='Årsutgifter'>{numToLocale(dashboardData.aarsutgifter)}</LogoCard>
        <LogoCard icon={BsPiggyBank} title={`Timer solgt i ${new Date().getFullYear()}`}>{`${dashboardData.timebank} / kr ${Math.round((dashboardData.timebank * 1290) / 1000)}k.-`}</LogoCard>
      </div>

      <div className='grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-12 gap-6 mt-8'>
        <LogoContainer icon={LiaToolsSolid} title='Siste teknisk' titleClassName='mb-0' iconClassName='text-green-400' className='col-span-6 xl:col-span-2 2xl:col-span-6'>
          <Table
            key='hen'
            className='!p-0'
            containerClassName='p-2 pt-0'
            header={[['', 'w-12'], ['Dato', 'w-24'], 'Tekst', 'Kunde']}
            isEmpty={!dashboardData.sisteHendelser || dashboardData.sisteHendelser.length <= 0}
          >
            {dashboardData.sisteHendelser.map((val: any) => {
              return <TableEntry
                key={`hen_${val.id}`}
                data={[
                  <Initial>{dashboardData.initialer[val.bruker_id]}</Initial>,
                  formatDateShort(val.dato),
                  <TableTooltip>{val.tekst}</TableTooltip>,
                  <TableTooltip><Link to={`/kunder/${val.kunde_id}`}>{val.kunde}</Link></TableTooltip>
                ]}
              />
            })}
          </Table>
        </LogoContainer>

        <LogoContainer
          icon={PiNotepadLight}
          title='Siste kunderelatert'
          titleClassName='mb-0'
          iconClassName='text-orange-300'
          className='col-span-6 xl:col-span-2 2xl:col-span-6'
        >
          <Table
            key='dnot'
            className='!p-0'
            containerClassName='p-2 pt-0'
            header={[['', 'w-12'], ['Dato', 'w-24'], 'Tekst', 'Kunde']}
            isEmpty={!dashboardData.sisteNotater || dashboardData.sisteNotater.length <= 0}
          >
            {dashboardData.sisteNotater.map((val: any) => {
              return <TableEntry
                key={`dnot_${val.id}`}
                data={[
                  <Initial>{dashboardData.initialer[val.bruker_id]}</Initial>,
                  formatDateShort(val.dato),
                  <TableTooltip>{val.tekst}</TableTooltip>,
                  <TableTooltip><Link to={`/kunder/${val.kunde_id}`}>{val.kunde}</Link></TableTooltip>
                ]}
              />
            })}
          </Table>
        </LogoContainer>

        <LogoContainer
          icon={MdOutlineLocalOffer}
          title='Utsendte tilbud'
          titleClassName='mb-0'
          className='col-span-6 xl:col-span-2 2xl:col-span-12 mb-2 mt-2'
          titleComponents={<Button customSpacing={'bg-transparent underline pl-2 pr-2'} onClick={() => {navigate('/salg');window.scrollTo(0, 0)}}>Se alle</Button>}
        >
          <Table
            key='d_tilb'
            className='!p-0'
            containerClassName='p-2 pt-0'
            header={['Sendt', 'Kommentar', 'Sist purring', 'Bekreftet']}
            isEmpty={!dashboardData.sisteKunder || dashboardData.sisteKunder.length <= 0}

          >
            {dashboardData.sisteTilbud.map((tilbud: any) => {
              return <TableEntry
                key={`d_tilb_${tilbud.id}`}
                data={[
                  formatDate(tilbud.sendt),
                  <TableTooltip>{tilbud.kommentar || 'N/A'}</TableTooltip>,
                  formatDate(tilbud.siste_purring),
                  formatDate(tilbud.bekreftet),
                ]}
              />
            })}
          </Table>
        </LogoContainer>

        <LogoContainer
          icon={FiUsers}
          title='Siste kunder'
          titleClassName='mb-0'
          className='col-span-6 xl:col-span-2 2xl:col-span-12'
          titleComponents={<Button customSpacing={'bg-transparent underline pl-2 pr-2'} onClick={() => {navigate('/kunder');window.scrollTo(0, 0)}}>Se alle</Button>}
        >
          <Table
            key='kun'
            className='!p-0'
            containerClassName='p-2 pt-0'
            header={['Dato', 'Kunde', 'Fakturamåned', 'Inntekt']}
            isEmpty={!dashboardData.sisteKunder || dashboardData.sisteKunder.length <= 0}

          >
            {dashboardData.sisteKunder.map((val: any) => {
              return <TableEntry
                key={`kun_${val.id}`}
                data={[
                  formatDateShort(val.kunde_siden) || 'N/A',
                  <Link to={`/kunder/${val.id}`}>{val.firma_navn || 'N/A'}</Link>,
                  numberToMonth[val.faktura_maaned] || 'N/A',
                  numToLocale(val.inntekt)
                ]}
              />
            })}
          </Table>
        </LogoContainer>

        {/* <LogoContainer icon={AiOutlineCloud} title='Siste publiseringer' titleClassName='mb-0' className='2xl:col-span-3'>
          <Table key='pub'
          className='!p-0'
          containerClassName='p-2 pt-0'
          header={['Dato', 'Applikasjon']}>
            {dashboardData.sistePubliseringer.map((val: any) => {
              return <TableEntry key={`pub_${val.id}`} data={[formatDateShort(val.dato), val.applikasjon_id]}/>
            })}
          </Table>
        </LogoContainer> */}
      </div>

      <LogoContainer className='mt-8 min-h-96' titleClassName='mb-6' icon={ImCalendar} title='Månedsbaserte inntekter'>
         <ResponsiveContainer width='100%' height={320}>
          <BarChart
            data={dashboardData.inntektData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3'/>
            <XAxis dataKey='name' tick={{ fill: 'white' }}/>
            <YAxis tick={{ fill: 'white' }} />
            <Tooltip cursor={{fill: '#1e293b'}} contentStyle={{backgroundColor: '#334155', color: '#fff'}} formatter={(value: any) => value && `${numToLocale(value)}`}/>
            <Legend/>
            <Bar dataKey='Pris' fill='#90c9f5'/>
          </BarChart>
        </ResponsiveContainer>
      </LogoContainer>
    </PageContainer>
</>);
}

export default Dashboard;
