import { ImSpinner9 } from 'react-icons/im';

const LogoContainer = ( props : any ) => {
  return (
    <div className={'bg-slate-700 p-4 pl-5 pr-5 relative ' + (props.className || '' )}>
      {!props.noHeader && <>
        <div className={'flex justify-between items-center'}>
          <p className={'flex items-center text-slate-300 ' + (props.titleClassName || '')}><props.icon size={20} className={`mr-2 ${props.iconClassName ? props.iconClassName : ''}`}/>{props.title}</p>
          {props.titleComponents && props.titleComponents}
        </div>
      </>}
      
      {props.isLoading && <>
          <div className='z-10 bg-slate-400/20 absolute top-12 bottom-2 left-2 right-2 flex justify-center items-center text-white'>
              <ImSpinner9 className='animate-spin' size={84}/>
          </div>
      </>}
      {props.children}
    </div>
  );
};
  
export default LogoContainer;