export const login = (bruker : string, passord : string, callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ b: bruker, p: passord })
    };

    fetch(apiUrl + '/auth/login', requestOptions)
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}

export const logout = (callback : Function) => {
    var apiUrl = process.env.REACT_APP_API_URL as string;

    fetch(apiUrl + '/auth/logout')
    .then(response => response.json())
    .then(json => callback(json))
    .catch(error => console.error(error));
}