import { useState, useEffect, isValidElement, cloneElement, ReactElement } from 'react';
import Paginator from './Paginator';
import { MdOutlineSearch } from "react-icons/md";
import { ImSpinner9 } from 'react-icons/im';
import { BiSad } from 'react-icons/bi'
import { FiChevronDown } from 'react-icons/fi';

import Input from '../Input';

const TableEntry = ( props: any ) => {
    const hasContextMenu = (typeof props.callbackContextMenu !== 'undefined');

    return (<>
        <tr
            className={`border-b-1 border-slate-500/30 last:border-b-0 
            ${hasContextMenu ? 'hover:bg-slate-500/40' : ''}
            ${props.onClick ? 'cursor-pointer' : ''}
            ${props.className ? props.className : ''}`}
            onClick={props.onClick}
            onContextMenu={(e) => {
                if (!hasContextMenu)
                    return;

                props.callbackContextMenu();

                e.preventDefault();
            }}
        >
            {props.data.map((item : any, index: number) =>
            <td key={`${props.passedKey}_${index}`} className='text-left pt-2 pb-2 pl-2 pr-2'>
                {item}
            </td>)}
        </tr>
    </>);
};
    
export {TableEntry};

const Table = ( props: any ) => {
    const isEmpty = props.isEmpty;

    const wrappedSortButton = (item: any) => {
        if (isValidElement(item))
            return cloneElement(item as ReactElement, {curSort: props.curSort, onSort: props.onSort})

        return item;
    }

    return (<>
        <div className={`bg-slate-700 pl-5 pr-5 pb-4 ${props.className ? props.className : ''}`}>
            <div className='flex justify-between items-center border-b-2 border-slate-500/30 mb-1 pb-2 pt-3'>
                {props.title && <p className='flex w-full items-center text-md text-slate-300'>{props.icon && <props.icon size={20} className={`mr-2 ${props.iconClassName ? props.iconClassName : ''}`}/>}{props.title}</p>}
                <div className={`flex gap-3 pb-1 ${props.tabButtonsClass ? props.tabButtonsClass : ''}`}>
                    {typeof props.tabButtons !== 'undefined' && props.tabButtons}
                    {typeof props.searchValue !== 'undefined' &&
                    <Input
                        icon={MdOutlineSearch}
                        type='search'
                        value={props.searchValue}
                        onChange={props.onSearchChange}
                        className='h-8 w-60'>
                    </Input>}
                </div>
            </div>
            
            <div className={`relative pl-2 pr-2 ${props.containerClassName ? props.containerClassName : ''}`}>
                <table className={`table-fixed border-collapse text-white min-w-full`}>
                    <thead>
                    <tr>
                        {props.header.map((item : any, index: number) => {
                            const isObject = typeof item === 'object' && item[1];
                            const realContent = isObject ? item[0] : item;

                            return <th key={`${index}_${item.props && item.props.children || realContent}`} className={`text-left pt-2 pb-2 pr-8 ${isObject ? item[1] : ''}`}>{wrappedSortButton(realContent)}</th>
                        })}
                    </tr>
                    </thead>
                    <tbody>
                        {props.children}
                    </tbody>
                </table>

                {props.isLoading && <>
                    {!isEmpty ? <>
                        <div className='bg-slate-400/20 absolute top-0 -left-1 -right-1 h-full flex justify-center items-center text-white'>
                            <div className='text-slate-300 flex flex-col items-center gap-2 pt-24 pb-24'>
                                <ImSpinner9 className='animate-spin' size={84}/>
                            </div>
                        </div>
                    </> : <>
                        <div className='bg-slate-400/20 absolute top-0 -left-1 -right-1 h-full flex justify-center items-center text-white'/>
                        <div className='text-slate-300 flex flex-col items-center gap-2 pt-24 pb-24'>
                            <ImSpinner9 className='animate-spin' size={84}/>
                        </div>
                    </>}
                </>}

                {(isEmpty && !props.isLoading) && <>
                    <div className='text-slate-300 flex flex-col items-center gap-2 pt-16 pb-12 mb-1'>
                        <BiSad size={84}/>
                        <span>Oww, her var det tomt.</span>
                    </div>
                </>}
            </div>
        </div>
        {props.showPaginator && <Paginator isLoading={props.isLoading} callbackPage={props.callbackPage} pageCount={props.pageCount} curPage={props.curPage}/>}
    </>);
};

export const SortButton = (props: any) => {
    const [isAsc, setIsAsc] = useState(true);
    const isSelected = props.sortBy === props.curSort;
    
    return(
        <button
            className='flex items-center gap-1 hover:opacity-60 transition'
            key={props.children}
            onClick={() => {
                let nextAsc = isSelected ? !isAsc : true;
                setIsAsc(nextAsc);

                props.onSort(props.sortBy, nextAsc);
            }}
        >
            {props.children}
            <FiChevronDown className={`${isSelected ? (isAsc ? '' : 'rotate-180') : 'hidden'}`}/>
        </button>
    );
}

export default Table;