import { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js/headless';
import 'tippy.js/dist/tippy.css';

const TableTooltip = (props: any) => {
    const [isOverflow, setIsOverflow] = useState(false);
    const [containerRef, paragraphRef] = [useRef(null), useRef(null)];

    useEffect(() => {
        const paragraphElem: any  = paragraphRef.current;
        const containerElem: any = containerRef.current;

        if (!paragraphElem || !containerElem) {
            setIsOverflow(false);

            return;
        }

        setIsOverflow(paragraphElem.offsetWidth >= containerElem.offsetWidth);
    }, [containerRef, paragraphRef, props.children]);

    return (<>
        <Tippy followCursor={true} disabled={!isOverflow} content={props.children} plugins={[followCursor]}>
            <div
                className={`overflow-hidden relative h-6`}
            >
            <div ref={containerRef} className={isOverflow ? 'absolute left-0 top-0 w-full h-full bg-gradient-to-l from-slate-700 z-10 pointer-events-none' : ''}/>
                <p className='w-fit [&>*:first-child]:whitespace-nowrap' ref={paragraphRef}>{props.children}</p>
            </div>
        </Tippy>
    </>)
}

export default TableTooltip;