import { useEffect, useState } from 'react';
import Modal from '../Modal';
import Input from '../Input';
import Button from '../Button';

import DatePicker from '../DatePicker';
import TextArea from '../TextArea';

import { BiPencil, BiPlus } from 'react-icons/bi';
import { putTilbud, postTilbud } from '../../api/salg'
import { createDate } from '../../utils/util';

const DomeneModal = (props: any) => {
    const [sendtTilbud, setSendtTilbud] = useState(new Date());
    const [tilbudKommentar, setTilbudKommentar] = useState('');
    const [sistePurring, setSistePurring] = useState(new Date());
    const [bekreftetTilbud, setBekreftetTilbud] = useState(new Date());

    const sendUpdate = (props: any) => {
        const parsed: any = {
            sendt: sendtTilbud ? Math.floor(sendtTilbud.getTime() / 1000) : Date.now(),
            kommentar: tilbudKommentar,
            siste_purring: sistePurring ? Math.floor(sistePurring.getTime() / 1000) : null,
            bekreftet: bekreftetTilbud ? Math.floor(bekreftetTilbud.getTime() / 1000) : null,
        }

        var modified = false;

        for (let key in parsed) {
            if (props.tilbudData[key] != parsed[key])
            {
                modified = true;
                break;
            }
        };

        if (!modified)
            return props.setOpen(false);

        const onFinished = () => {
            props.setOpen(false);
            props.updateTable();
        }

        if (props.tilbudData.ny) {
            postTilbud(parsed, onFinished);
        } else {
            putTilbud(props.tilbudData.id, parsed, onFinished);
        }
    }

    useEffect(() => {
        setSendtTilbud(createDate(props.tilbudData.sendt, true));
        setTilbudKommentar(props.tilbudData.kommentar || '');
        setSistePurring(createDate(props.tilbudData.siste_purring));
        setBekreftetTilbud(createDate(props.tilbudData.bekreftet));
    }, [props.tilbudData])
  
    return(<>
      <Modal
        title={props.tilbudData.ny ? 'Nytt tilbud' : 'Rediger tilbud'} 
        icon={props.tilbudData.ny ? BiPlus : BiPencil} 
        onClose={() => {props.setOpen(false)}}
      >
        <form onSubmit={e => {e.preventDefault(); sendUpdate(props)}}>
            <h3 className='mt-4 mb-1'>Sendt</h3>
            <DatePicker isClearable showIcon selected={sendtTilbud} setSelected={setSendtTilbud} />

            <h3 className='mb-1'>Kommentar</h3>
            <TextArea value={tilbudKommentar} onChange={(e: any) => setTilbudKommentar(e.target.value)} type='text'/>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div className='flex flex-col'>
                    <h3 className='mt-4 mb-1'>Siste purring</h3>
                    <DatePicker isClearable showIcon selected={sistePurring} setSelected={setSistePurring} />
                </div>

                <div className='flex flex-col'>
                    <h3 className='mt-4 mb-1'>Bekreftet</h3>
                    <DatePicker isClearable showIcon selected={bekreftetTilbud} setSelected={setBekreftetTilbud} />
                </div>
            </div>

            <Button
                className='mt-6'
                type="submit"
            >
                {props.tilbudData.ny ? 'Legg til' : 'Lagre endringer'}
            </Button>
        </form>
      </Modal>
    </>);
}

export default DomeneModal;