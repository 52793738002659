import { createSlice } from '@reduxjs/toolkit';
import { numberToMonth } from '../utils/util';

var tomInntektData: any = [];

for (let i = 1; i <= 12; i++) {
  tomInntektData[i - 1] = {name: numberToMonth[i], Pris: 0};
}

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    value: {
      'kundeCount': 0,
      'domeneCount': 0,
      'aarsinntekt': 0,
      'aarsutgifter': 0,
      'timebank': 0,
      'sisteKunder': [],
      'sisteHendelser': [],
      'sisteNotater': [],
      'sistePubliseringer': [],
      'sisteTilbud': [],
      'inntektData': tomInntektData
    }
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    }
  }
});

// Action creators are generated for each case reducer function
export const { set } = dashboard.actions;

export default dashboard.reducer;