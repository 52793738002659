import { useState } from 'react';
import UtgiftContextMenu, { ContextButton } from '../ContextMenu';
import UtgiftModal from './UtgiftModal';
import Table, { TableEntry } from '../Table';
import Button from '../Button';

import { formatDate, numToLocale } from '../../utils/util';
import { deleteUtgift } from '../../api/okonomi';

import { ImCheckmark, ImCross } from 'react-icons/im';
import { CgMathPlus } from 'react-icons/cg';

const NyUtgiftData = (type: Number) => {
    const nyData: any = {
        til: '',
        fritekst: '',
        pris: 0,
        valuta: 0,
        betalings_frekvens: 0,
        loopende: false,
        neste_betaling: null,
        sist_betalt: null,
        type: type,
        kunde_id: 0,
        ny: true
    }

    return nyData;
}

const UtgiftTable = (props: any) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedUtgift, setSelectedUtgift] = useState(-1);
    const [utgiftData, setUtgiftData] = useState([]);

    const [isEditModalOpen, setIsUtgiftModalOpen] = useState(false);
  
    return (<>
      <UtgiftContextMenu isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
        <ContextButton onClick={() => {setUtgiftData(props.data['utgifter'][selectedUtgift]);setIsUtgiftModalOpen(true)}}>Rediger</ContextButton>
        <ContextButton onClick={() => {
          deleteUtgift(props.data['utgifter'][selectedUtgift].id, () => {props.updateUtgifter()});
        }}>Slett</ContextButton>
      </UtgiftContextMenu>
  
      {isEditModalOpen && <UtgiftModal setOpen={setIsUtgiftModalOpen} updateDrift={props.updateDrift} updateLisenser={props.updateLisenser} utgiftData={utgiftData}/>}
  
      <Table
        title={props.title}
        icon={props.icon}
        showPaginator={props.data['pageCount'] > 1}
        curPage={props.curPage}
        searchValue={props.searchValue}
        onSearchChange={(e: any) => {
          props.setSearchVal(e.target.value);
        }}
        tabButtons={
            <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setUtgiftData(NyUtgiftData(props.title == "Drift" ? 0 : 1)); setIsUtgiftModalOpen(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
        }
        pageCount={props.data['pageCount']}
        className={`flex flex-col min-h-96 ${props.className ? props.className : props.className}`}
        containerClassName={'grow'}
        isLoading={props.isLoading}
        callbackPage={props.setPageCount}
        header={['Navn', 'Beskrivelse', 'Kostnad', 'Siste betaling', 'Neste betaling', 'Løpende']}>
          {props.data['utgifter'].map((utgift: any, index: number) => {
            return <TableEntry
              callbackContextMenu={() => {setIsDropdownOpen(true); setSelectedUtgift(index)}}
              dbId={utgift.id}
              key={`utg_${utgift.id}`}
              passedKey={`utg_${utgift.id}`}
              data={[
                utgift.til,
                utgift.fritekst,
                `${numToLocale(utgift.konvertert_pris)}`,
                formatDate(utgift.sist_betalt),
                formatDate(utgift.neste_betaling),
                utgift.loopende ? <ImCheckmark size={14}/> : <ImCross size={14}/>
              ]}/>
          })
        }
    </Table>
    </>);
}

export default UtgiftTable;