import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import ContextMenu, { ContextButton } from '../ContextMenu';
import TilbudModal from './TilbudModal';
import Table, { TableEntry, SortButton,  } from '../Table';
import TableTooltip from '../Table/Tooltip'

import Button from '../Button';

import { formatDate } from '../../utils/util';
import { getTilbud, deleteTilbud } from '../../api/salg';

import { CgMathPlus } from 'react-icons/cg';

const TilbudTable = (props: any) => {
    const [selectedTilbud, setSelectedTilbud] = useState(-1);
    const [modalTilbudData, setModalTilbudData] = useState({});
    const [tilbudData, setTilbudData] = useState<any>({results: []});

    const [curPage, setCurPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [debouncedSearch] = useDebounce(searchVal, 300);
    const [sortData, setSortData] = useState({sortBy: 'sendt', isAsc: false});

    const updateTable = () => {
      setIsLoading(true);

      getTilbud(curPage, debouncedSearch, sortData.sortBy, sortData.isAsc, (json: any) => {
        setTilbudData(json);
        setIsLoading(false);
      });
    }

    useEffect(() => {
      updateTable();
    }, [debouncedSearch, curPage, sortData]);
  
    return (<>
      <div className={props.containerClassName}>
        <ContextMenu isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
          <ContextButton
            onClick={() => {setModalTilbudData(tilbudData.results[selectedTilbud]);setIsEditModalOpen(true)}}
          >Rediger</ContextButton>
          <ContextButton
            onClick={() => {deleteTilbud(tilbudData.results[selectedTilbud].id, updateTable)}}
            >Slett</ContextButton>
        </ContextMenu>
    
        {isEditModalOpen && <TilbudModal setOpen={setIsEditModalOpen} updateTable={updateTable} tilbudData={modalTilbudData}/>}
    
        <Table
          title={props.title}
          icon={props.icon}
          iconClassName={props.iconClassName}
          className={`min-h-96 ${props.className ? props.className : ''}` }
          showPaginator={tilbudData['pageCount'] > 1}
          curPage={curPage}
          searchValue={searchVal}
          curSort={sortData.sortBy}
          onSort={(sort_str: string, asc: boolean) => {
            setSortData({
              sortBy: sort_str,
              isAsc: asc
            });
          }}
          isEmpty={!tilbudData.results || tilbudData.results.length <= 0}
          onSearchChange={(e: any) => {
            setSearchVal(e.target.value);
          }}
          tabButtons={
              <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setModalTilbudData({'ny': true}); setIsEditModalOpen(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
          }
          pageCount={tilbudData['pageCount']}
          containerClassName={'grow'}
          isLoading={isLoading}
          callbackPage={setCurPage}
          header={[
            <SortButton sortBy='sendt'>Sendt</SortButton>,
            <SortButton sortBy='kommentar'>Kommentar</SortButton>,
            <SortButton sortBy='siste_purring'>Sist purring</SortButton>,
            <SortButton sortBy='bekreftet'>Bekreftet</SortButton>,
          ]}>
            {tilbudData.results.map((tilbud: any, index: number) => {
              return <TableEntry
                callbackContextMenu={() => {setSelectedTilbud(index); setIsDropdownOpen(true)}}
                key={`tilb_${tilbud.id}`}
                passedKey={`tilb_${tilbud.id}`}
                data={[
                  formatDate(tilbud.sendt),
                  <TableTooltip>{tilbud.kommentar || 'N/A'}</TableTooltip>,
                  formatDate(tilbud.siste_purring),
                  formatDate(tilbud.bekreftet),
                ]}/>
            })}
        </Table>
      </div>
    </>);
}

export default TilbudTable;