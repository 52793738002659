import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import ContextMenu, { ContextButton } from '../../ContextMenu';
import TimebankModal from './TimebankModal';
import TimebankManageTable from './Manage/TimebankManageTable';
import Table, { TableEntry } from '../../Table';
import Button from '../../Button';
import TableTooltip from '../../Table/Tooltip';

import { formatDate, numToLocale } from '../../../utils/util';
import { getTimebanker } from '../../../api/kunder';
import { deleteTimebank } from '../../../api/timebanker';

import { CgMathPlus } from 'react-icons/cg';
import { BsPiggyBank } from 'react-icons/bs';

const TimebankTable = (props: any) => {
    const [selectedTimebank, setSelectedTimebank] = useState(-1);
    const [modalTimebankData, setModalTimebankData] = useState({});
    const [timebankData, setTimebankData] = useState<any>({results: []});

    const [timerTilgode, setTimerTilgode] = useState<any>(0);
    const [curPage, setCurPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [curManageTimebankModal, setCurManageTimebankModal] = useState();
    const [searchVal, setSearchVal] = useState('');
    const [debouncedSearch] = useDebounce(searchVal, 300);
    const { kundeId } = useParams() as any;

    const updateTable = () => {
      setIsLoading(true);

      getTimebanker(kundeId, curPage, debouncedSearch, (json: any) => {
        json.results.sort(function(a: any, b: any){
          return (b.timer - b.timerBrukt) - (a.timer - a.timerBrukt);
        })

        setTimebankData(json);
        setIsLoading(false);

        let timer = 0;

        json.results.map((timebank: any, index: number) => {
          timer += timebank.timer - timebank.timerBrukt;
        });

        setTimerTilgode(timer.toFixed(1).replace(/\.0$/, ''));
      });

      props.getKunde();
    }

    useEffect(() => {
      updateTable();
    }, [debouncedSearch, curPage]);

    useEffect(() => {
      timebankData.results.forEach((timebank: any) => {
        if (curManageTimebankModal && curManageTimebankModal['id'] == timebank.id) {
          setCurManageTimebankModal(timebank);
        }
      });
    }, [timebankData])
  
    return (<>
      <div className={props.containerClassName}>
        <ContextMenu isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
          <ContextButton
            onClick={() => {setModalTimebankData(timebankData.results[selectedTimebank]);setIsEditModalOpen(true)}}
          >Rediger</ContextButton>
          <ContextButton
            onClick={() => {deleteTimebank(timebankData.results[selectedTimebank].id, updateTable)}}
            >Slett</ContextButton>
        </ContextMenu>
    
        {isEditModalOpen && <TimebankModal setOpen={setIsEditModalOpen} updateTable={updateTable} timebankData={modalTimebankData}/>}
        {curManageTimebankModal != null && <TimebankManageTable updateTimebankerTable={updateTable} curTimebank={curManageTimebankModal} setOpen={setCurManageTimebankModal} updateTable={updateTable} timebankData={modalTimebankData}/>}

        <Table
          title={`Timebank - Timer til gode: ${timerTilgode}`}
          icon={BsPiggyBank}
          iconClassName={props.iconClassName}
          className={`min-h-96 ${props.className ? props.className : ''}`}
          showPaginator={timebankData['pageCount'] > 1}
          curPage={curPage}
          searchValue={searchVal}
          onSearchChange={(e: any) => {
            setSearchVal(e.target.value);
          }}
          tabButtons={
              <Button customSpacing={'bg-emerald-500 pl-1 pr-1 w-8 flex justify-center items-center'} onClick={() => {setModalTimebankData({'ny': true, 'kunde_id': kundeId}); setIsEditModalOpen(true)}}><CgMathPlus className={'text-gray-100'} size={32}/></Button>
          }
          pageCount={timebankData['pageCount']}
          isLoading={isLoading}
          isEmpty={!timebankData.results || timebankData.results.length <= 0}
          callbackPage={setCurPage}
          header={[['Dato', 'w-40'], 'Beskrivelse', 'Timer', 'Timer til gode']}>
            {timebankData.results.map((timebank: any, index: number) => {
              const timerTilgode: any = (timebank.timer - timebank.timerBrukt);
              
              return <TableEntry
                callbackContextMenu={() => {setSelectedTimebank(index); setIsDropdownOpen(true)}}
                key={`kun_hnd_${timebank.id}`}
                onClick={() => {setCurManageTimebankModal(timebank);}}
                passedKey={`kun_hnd_${timebank.id}`}
                className={(parseInt(timerTilgode) <= 0) ? `relative after:z-30 after:left-2 after:right-2 after:content after:absolute after:h-0.5 after:top-1/2 after:-translate-y-1/2 after:bg-red-500/80 opacity-70 order-10` : ''}
                data={[
                  formatDate(timebank.dato),
                  <TableTooltip>{timebank.beskrivelse}</TableTooltip>,
                  timebank.timer,
                  timerTilgode.toFixed(1).replace(/\.0$/, '')
                ]}/>
            })}
        </Table>
      </div>
    </>);
}

export default TimebankTable;