import { Helmet } from 'react-helmet';
import Table, { TableEntry } from '../components/Table';
import PageContainer from '../components/PageContainer';

function Applikasjoner() {
  return (<>
    <Helmet>
      <title>Webtron - Applikasjoner</title>
    </Helmet>

    <PageContainer>
      <Table
      title="Oversikt"
      showPaginator={true}
      header={["Domene", "Serverlokasjon", "MailGun", "Admin", "Websystem", "Pris", "Merknader", "Status", "Kunde", "Webhotell"]}>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
        <TableEntry data={["aksethauto.no", "Pro ISP", "Ja", "Åpne", "Wordpress", "v2.4", "199", "Kunde har domene hos Uniweb. DNS hos Microsoft.", "Aktiv", "Akseth Auto AS"]}></TableEntry>
      </Table>
    </PageContainer>
  </>);
}

export default Applikasjoner;
