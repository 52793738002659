import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';
import { CgInfo } from 'react-icons/cg';
import { LuContact } from 'react-icons/lu'
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { BsPiggyBank, BsTrash } from 'react-icons/bs';
import { AiOutlineCloud, AiOutlineOrderedList } from 'react-icons/ai';
import { PiNotepadLight } from 'react-icons/pi';
import { LiaToolsSolid } from 'react-icons/lia'

import PageContainer from '../components/PageContainer';
import LogoContainer from '../components/LogoContainer';
import DomeneTable from '../components/Kunder/DomeneTable';
import HendelseTable from '../components/Kunder/HendelseTable';
import NotatTable from '../components/Kunder/NotatTable';
import KundeModal from '../components/Kunder/KundeModal';
import TimebankTable from '../components/Kunder/Timebank/TimebankTable';
import InntektTable from '../components/Kunder/VisKunde/InntektTable';

import Modal from '../components/Modal';
import Button from '../components/Button';

import { getKunde, deleteKunde } from '../api/kunder'
import { numberToMonth, formatDate, numToLocale } from '../utils/util';

const formatTlf = (tlf: any) => {
  if (typeof tlf === 'undefined' || tlf == 0)
    return 'N/A'

  tlf = String(tlf);

  return `${tlf.substr(0, 3)} ${tlf.substr(3, 2)} ${tlf.substr(5, 3)}`;
}

const VisKunde = () => {
  const [kunde, setKunde] = useState({}) as any;
  const { kundeId } = useParams() as any;
  const [showKundeModal, setShowKundeModal] = useState(false);
  const [showSlettModal, setShowSlettModal] = useState(false);
  const navigate = useNavigate();

  const updateKunde = () => {
    getKunde(kundeId, (json: any) => {if (json[0]) {setKunde(json[0])}});
  }

  useEffect(() => {
    updateKunde();
  }, [])
  
  return (<>
    <Helmet>
      <title>Webtron - Kunde</title>
    </Helmet>
    
    {showKundeModal && <KundeModal setOpen={setShowKundeModal} kundeData={kunde} performUpdate={updateKunde}/>}
    {showSlettModal && <>
      <Modal
        title='Er du sikker?'
        icon={BsTrash}
        onClose={() => setShowSlettModal(false)}
      >
        <p className='mt-4'>Sikker på at du vil slette kunden?</p>
        
        <div className='flex gap-2 mt-1'>
          <Button
            className='mt-0 bg-blue-500'
            onClick={() => {setShowSlettModal(false)}}
          >Avbryt</Button>

          <Button
            className='mt-0 bg-red-500'
            onClick={() => {
              deleteKunde(kundeId, () => {
                navigate('/kunder');
                setShowSlettModal(false);
              });
            }}
          >Slett</Button>
        </div>
        <></>
      </Modal>
    </>}
    <div className='h-14 bg-slate-700 w-full flex items-center'>
        <div className='flex justify-between items-center w-full pl-8 pr-8'>
          <p className='text-white text-xl flex items-center'><FiUser className='mr-2'/>Kunde - {kunde.firma_navn}</p>
          <div className='flex gap-2'>
            <Button
              className='!mt-0 bg-blue-500'
              onClick={() => {setShowKundeModal(true)}}
            >Rediger</Button>

            <Button
              className='!mt-0 bg-red-500'
              onClick={() => {
                setShowSlettModal(true)
              }}
            >Slett</Button>
          </div>
        </div>
    </div>

    <PageContainer>
      <div className='grid grid-cols-1 md:grid-cols-6 gap-6'>
        {kunde.merknader && <>
          <div className='p-3 bg-blue-500/40 text-slate-100 col-span-6'>
            {kunde.merknader}
          </div>
        </>}

        <LogoContainer title='Kontaktinfo' className='col-span-2' icon={LuContact}>
          <div className='flex text-white flex-col p-3 gap-1'>
            <span>Kontaktperson: {kunde.kontakt_person || 'N/A'}</span>
            <span>Epost: {kunde.epost ? <a href={`mailto:${kunde.epost}`}>{kunde.epost}</a> : 'N/A'}</span>
            <span>Tlf: <a href={`tel:${kunde.telefon}`}>{formatTlf(kunde.telefon)}</a></span>
          </div>
        </LogoContainer>

        <LogoContainer title='Fakturering' className='col-span-2' icon={LiaFileInvoiceDollarSolid}>
          <div className='flex text-white flex-col p-3 gap-1'>
              <span>Fakturamåned: {numberToMonth[kunde.faktura_maaned]}</span>
              <span>Inntekt: {numToLocale(kunde.inntekt)}</span>
              <span>Utgift: {numToLocale(kunde.utgift)}</span>
          </div>
        </LogoContainer>

        <LogoContainer title='Info' className='col-span-2' icon={CgInfo}>
          <div className='flex text-white flex-col p-3 gap-1'>
              <span>Kunde siden: {formatDate(kunde.kunde_siden)}</span>
              <span>Siste redesign: {formatDate(kunde.siste_redesign)}</span>
          </div>
        </LogoContainer>

        <HendelseTable
          containerClassName='col-span-3'
          getKunde={updateKunde}
        />

        <NotatTable
          containerClassName='col-span-3'
          getKunde={updateKunde}
        />

        <TimebankTable
          containerClassName='col-span-6'
          getKunde={updateKunde}
        />

        <InntektTable
          containerClassName='col-span-6'
          getKunde={updateKunde}
          kundeFakturaMaaned={kunde.faktura_maaned}
        />

        <DomeneTable
          containerClassName='col-span-6'
          getKunde={updateKunde}
        />
      </div>
    </PageContainer>
  </>);
}

export default VisKunde;
