import logo from '../assets/svg/logo-liten-hvit.svg';

const colorFromInitials = (initial: string) => {
    if (initial === undefined)
        return 'bg-webtron';

    const charCode = initial.charCodeAt(0);

    if (charCode < 70) {
        return 'bg-red-500';
    } else if (charCode < 75) {
        return 'bg-sky-500';
    } else if (charCode < 80) {
        return 'bg-emerald-500';
    } else if (charCode < 85) {
        return 'bg-purple-500';
    } else if (charCode < 95) {
        return 'bg-pink-500';
    }

    return 'bg-orange-600';
}

const Initial = (props: any) => {
    return(<>
        <div className={`rounded-full w-7 h-7 text-xs flex items-center font-medium justify-center ${colorFromInitials(props.children)}`}>
            {props.children || <img src={logo} className='h-3.5'/>}
        </div>
    </>);
}

export default Initial;